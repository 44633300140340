import React, {Component} from 'react';
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import HostedImage from "../../shared/HostedImage";
import PreviewVideo from "../video/PreviewVideo";
import ReactionCounts from "../shared/ReactionCounts";

class Container extends Component {
  static defaultProps = {
    mixed: false,
    title: false,
    size: "large"
  };

  constructor(props) {
    super(props);

    this.state = {
      hovered: false
    };

    this.onHover = this.onHover.bind(this);
    this.onLeave = this.onLeave.bind(this);
  }

  componentDidMount() {
    this._container.addEventListener('mouseover', this.onHover);
    this._container.addEventListener('mouseout', this.onLeave);
  }

  componentWillUnmount() {
    this._container.removeEventListener('mouseover', this.onHover);
    this._container.removeEventListener('mouseout', this.onLeave);
  }

  onHover() {
    this.setState({ hovered: true });
  }

  onLeave() {
    this.setState({ hovered: false });
  }

  render() {
    const { image } = this.props;
    let preview = image;
    if (image.type === "Video") {
      preview = image.images[0];
    }

    let url = `/${image.type === 'Video' ? 'gif' : 'image'}/${image.slug || image.id}`;
    if (this.props.fullUrl) {
      url = this.props.fullUrl;
    }

    return (
      <article className={'object-container'} ref={(node) => { this._container = node; }}>
        <Link to={url} className={'image-container type-' + image.type} title={image.title || "Untitled"}>
          {image.type === 'Video' && <div className={'badge gif-badge'}>Gif</div>}
          <HostedImage image={preview} className={'img-fluid'} alt={image.title || "Untitled"} mixed={this.props.mixed} size={this.props.size} />
          {this.props.mixed && <i className={'fas fa-image icon'} />}
          {image.type === 'Video' && <PreviewVideo video={image} visible={this.state.hovered} />}
          <ReactionCounts object={image} />
        </Link>
      </article>
    );
  }
}

Container.propTypes = {
  image: PropTypes.object,
  mixed: PropTypes.bool,
  title: PropTypes.bool,
  size: PropTypes.string
};

export default Container;