import React from "react"
import { NavLink } from "react-router-dom";
import {connect} from "react-redux";

import SearchForm from "../porn_app/layout/SearchForm";
import Burger from "./Burger";
import WithLogin from "./user/WithLogin";
import WithoutLogin from "./user/WithoutLogin";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      burgerVisible: false,
      loggedIn: false,
      logo: "",
      site_title: ""
    };

    this.onBurgerOpen = this.onBurgerOpen.bind(this);
    this.onBurgerClose = this.onBurgerClose.bind(this);
    this.onUserStateChange = this.onUserStateChange.bind(this);
  }

  componentDidMount() {
    this.setState({ logo: window.settings.logo, title: settings.title, site_title: settings.site_title, loggedIn: _sp.user.isLoggedIn() });
    window.addEventListener('userStateChanged', this.onUserStateChange);
  }

  componentWillUnmount() {
    window.removeEventListener('userStateChanged', this.onUserStateChange);
  }

  onUserStateChange() {
    this.setState({ loggedIn: _sp.user.isLoggedIn() });
  }

  onBurgerOpen() {
    this.setState({ burgerVisible: true });
  }

  onBurgerClose() {
    this.setState({ burgerVisible: false });
  }

  toggleSearch() {
    document.querySelector('.mobile-header .search-form').classList.toggle('visible');
    document.querySelector('.mobile-header .search-form input').focus();
  }

  render () {
    const { navigation } = this.props;

    return (
      <React.Fragment>
        <header className={'partner'}>
          <nav className={'container'}>
            <ul>
              <li className={'title'}>Simply Network</li>
              {navigation.partner.map((nav) => {
                return <li key={nav.url}><a className={nav.active ? 'active' : ''} href={nav.url + '?utm_source='+this.state.title+'&utm_medium=network-bar&utm_campaign='+this.state.title+'-networkbar'} rel="noopener" target={"_blank"}>{nav.title}</a></li>
              })}
            </ul>
          </nav>
        </header>
        <header className="page-header">
          <nav className={'container'}>
            <ul className={'primary'}>
              <li className="logo"><NavLink to={"/"} activeClassName={'active'}><div dangerouslySetInnerHTML={ { __html: this.state.logo } } /> <div>{this.state.site_title}</div></NavLink></li>
              {navigation.primary.map((nav) => {
                return <li key={nav.url}><NavLink to={nav.url} exact={nav.url === "/"} activeClassName={'active'}>{nav.title}</NavLink></li>
              })}
              <li className={'search'}>
                <SearchForm />
              </li>
            </ul>
          </nav>
        </header>
        <header className={'user-header'}>
          <nav className={'container'}>
            <ul>
              <WithLogin>
                <li><button onClick={() => { _sp.user.signOut() }}>Logout</button></li>
              </WithLogin>
              <WithoutLogin>
                <li><button onClick={() => { window.dispatchEvent(new Event('showSignIn')) }}>Login</button></li>
                <li><button onClick={() => { window.dispatchEvent(new Event('showSignUp')) }}>Register</button></li>
              </WithoutLogin>
            </ul>
          </nav>
        </header>
        <header className="mobile-header">
          <nav className={'container'}>
            <div className={'row'}>
              <div className={'col-1'}><button aria-label={"mobile-menu"} onClick={this.onBurgerOpen}><i className={'fas fa-bars'} /></button></div>
              <div className={'col-10 text-center'}>
                <NavLink className={'logo'} to={"/"} activeClassName={'active'}><div dangerouslySetInnerHTML={ { __html: this.state.logo } } /> <div>{this.state.site_title}</div></NavLink>
              </div>
              <div className={'col-1'}>
                <SearchForm />
                <button aria-label={"search-toggle"} onClick={this.toggleSearch}><i className={'fas fa-search'} /></button>
              </div>
            </div>
          </nav>
        </header>

        {this.state.burgerVisible && <Burger closeCallback={this.onBurgerClose} />}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return { navigation: state.navigation };
}

export default connect(mapStateToProps)(Header);