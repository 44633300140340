import React from 'react';

export default class VideoPlayer extends React.Component {
  constructor(props) {
    super(props);

    this._videoId = 'video-player-' + Math.ceil(Math.random() * 1000);

    this.state = {
      autoplay: typeof(props.autoplay) !== "undefined" ? props.autoplay : true,
      controlState: props.controlState || "default"
    }
  }

  componentDidMount() {
    this.player = videojs(this.videoNode, {
      ...this.props,
      plugins: {
        persistvolume: {
          namespace: this.props.namespace || 'foobar'
        }
      }
    });

    if (this.props.muted || this.props.mute) {
      this.player.muted(true);
    }

    if (this.props.advert === "preroll") {
      this.player.ima({
        id: this._videoId,
        adTagUrl: 'https://syndication.exoclick.com/splash.php?idzone=3390817',
        numRedirects: 1
      });
    }

    /*if (this.props.advert === "banner") {
      this.player.ima({
        id: this._videoId,
        adTagUrl: 'https://syndication.exoclick.com/splash.php?idzone=3390867',
        numRedirects: 1
      });
    }*/
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.muted !== prevProps.muted) {
      this.videoNode.muted = this.props.muted;
    }
  }

  componentWillUnmount() {
    if (this.player && typeof(this.player.dispose) === "function") {
      this.player.dispose()
    }
  }

  play() {
    if (!this.playing()) {
      this.player.play();
    }
  }

  pause() {
    if (this.playing()) {
      this.player.pause();
    }
  }

  playing() {
    return !!(this.videoNode.currentTime > 0 && !this.videoNode.paused && !this.videoNode.ended && this.videoNode.readyState > 2);
  }

  render() {
    let classes = ["video-js"];
    if (this.props.className) {
      classes.push(this.props.className);
    } else {
      classes.push("vjs-16-9")
    }

    return (
      <div className={'video-container ' + (this.props.controls === false ? "no-controls" : '')}>
        <video ref={ node => this.videoNode = node } autoPlay={this.state.autoplay} className={classes.join(" ")} id={this._videoId} poster={this.props.poster} />
      </div>
    )
  }
}