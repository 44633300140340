import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";

const awesomplete = require('awesomplete');

class SearchForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: undefined
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.initializeAutosuggest();

    let value = _sp.dom.getParameterByName('query');
    if (value && value !== "") {
      this._inputField.value = value;
    }
  }

  initializeAutosuggest() {
    this.autocomplete = new awesomplete(this._inputField, {
      autoFirst: false,
      minChars: 3
    });

    this._autoSuggestLoaded = true;

    this.completeTimeout = undefined;
    this._inputField.addEventListener('keyup', (e) => {
      let value = e.currentTarget.value;

      if (value.length > 2 && (e.which <= 90 && e.which >= 48 || e.which >= 96 && e.which <= 105)) {
        clearTimeout(this.completeTimeout);

        this.completeTimeout = setTimeout(() => {
          _sp.axios.get(`${settings.api_base}/${settings.api_version}/${settings.urls.autocomplete}`, {
            params: {
              token: settings.api_token,
              query: value
            }
          }).then((res) => {
            let values = res.data.filter(v => !!v);
            this.autocomplete.list = values;
          });
        }, 250);
      }
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let value = _sp.dom.getParameterByName('query');
    if (value !== this.state.value) {
      this._form.querySelector("input").value = value;
    }

    this._form.classList.remove('visible');
  }

  onSubmit(e) {
    e.preventDefault();

    let elem = this._form.querySelector("input");
    let value = elem.value.replace("#", "");
    elem.value = "";

    let args = {};

    if (this.props.buildParams) {
      args = this.props.buildParams();
    }

    args.query = encodeURI(value);

    let url = "/search?" + _sp.dom.buildQueryString(args);

    _sp.visit(url);
  }

  render() {
    return (
      <form className={'search-form'} onSubmit={this.onSubmit} ref={(e) => { this._form = e; }}>
        <button aria-label={"search-submit"}><i className={'far fa-search'} /></button>
        <input aria-label={"Search Query"} type={'text'} name={'query'} placeholder={'Search...'} ref={(e) => { this._inputField = e; }} defaultValue={this.state.value} />
      </form>
    );
  }
}

SearchForm.propTypes = {
  buildParams: PropTypes.func
};

export default withRouter(SearchForm);