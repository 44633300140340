import React from "react"
import { withRouter } from "react-router-dom";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import {fetchCam} from "../../../redux/cams";

import Loader from "../../shared/Loader";
import ObjectList from "../shared/ObjectList";
import Container from "../cam/Container";
import SocialBar from "../utility/SocialBar";

class CamShow extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.cam.loading) {
      this.loadCam();
    }

    _sp.changeMeta(this.props.cam);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.match.params.slug !== this.props.match.params.slug) {
      this.loadCam();
    }

    _sp.changeMeta(this.props.cam);
  }

  loadCam() {
    this.props.fetchCam(this.props.match.params.slug, {
      provider: this.props.match.params.provider,
      related: 16
    });
  }

  render () {
    const { cam } = this.props;

    if (cam.loading) {
      return <Loader />;
    }

    let related = cam.related;

    return (
      <div className={'cam-show'}>
        <div className={'light-container'}>
          <div className={'container'}>
            <div className={'row'}>
              <div className={'col-12 col-lg-9'}>
                <div className={'embed-container'}>
                  <iframe src={cam.embed} />
                </div>
              </div>
              <div className={'col-12 col-lg-3'}>
                <SocialBar object={cam} type={'cam'} className={'mb-3'} />

                <h3 className={'section-headline'}>More Videos</h3>
                {related && related.length > 0 && (
                  <div className={'row full-gutters'}>
                    {related.slice(0, 4).map((cam) => {
                      return (
                        <div className={'col-6'} key={cam.slug}>
                          <Container cam={cam} mixed={true} title={true} />
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className={'container'}>
          <h1 className={'mt-3 mb-3'}>{cam.title || cam.name || cam.username}</h1>

          {cam.tags && cam.tags.filter(t => t.tag_type === "category").length > 0 && <div className={'object-list'}><strong>Categories:</strong> <ObjectList url={"/cams/category/"} objects={cam.tags.filter(t => t.tag_type === "category") } /></div>}
          {cam.tags && cam.tags.filter(t => t.tag_type === "default").length > 0 && <div className={'object-list'}><strong>Tags:</strong> <ObjectList url={"/cams/category/"} objects={cam.tags.filter(t => t.tag_type === "default") } /></div>}

          <h3 className={'section-headline mt-4'}>Related Cams</h3>
          {related && related.length > 0 && (
            <div className={'row full-gutters'}>
              {related.slice(4, related.length).map((cam) => {
                return (
                  <div className={'col-6 col-lg-2'} key={cam.slug}>
                    <Container cam={cam} mixed={true} title={true} />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  let slug = ownProps.match.params.slug;
  if (state.cam && state.cam.username === slug) {
    return { cam: state.cam };
  }

  return { cam: { loading: true } };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchCam }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CamShow));