import React, {Component} from 'react';
import {Link} from "react-router-dom";

import HostedImage from "../../shared/HostedImage";

class Container extends Component {
  render() {
    const { pornstar } = this.props;

    return (
      <article className={'object-container'}>
        <Link to={`${this.props.fullUrl || '/pornstar/' + pornstar.slug}`} className={'image-container'} title={pornstar.name}>
          <HostedImage image={pornstar.preview} mixed={false} className={'full'} alt={pornstar.name} mixed={this.props.mixed} />
          {this.props.mixed && <i className={'fas fa-user icon'} />}
        </Link>
        {(!this.props.mixed || this.props.title) && (
          <div className={'info-row'}>
            <div className={'utility'}>
              <i className={'far fa-video'} /> {(pornstar.video_count || 0).toLocaleString()}
              <i className={'far fa-image'} /> {(pornstar.image_count || 0).toLocaleString()}
              <i className={'far fa-images'} /> {(pornstar.gallery_count || 0).toLocaleString()}
            </div>

            <h3><Link to={`/pornstar/${pornstar.slug}`} title={pornstar.name}>{pornstar.name}</Link></h3>
          </div>
        )}
      </article>
    );
  }
}

export default Container;