import React, {Component} from 'react';

class VoteButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      voted: props.active || false
    };

    this.rateObject = this.rateObject.bind(this);
    this.onObjectRated = this.onObjectRated.bind(this);
  }

  componentDidMount() {
    this.setState({
      voted: _sp.storage.get("vote-" + this.props.objectType + "-" + (this.props.object.slug || this.props.object.id)) === this.props.type
    });

    window.addEventListener('objectRated', this.onObjectRated);
  }

  componentWillUnmount() {
    window.removeEventListener('objectRated', this.onObjectRated);
  }

  onObjectRated(e) {
    let object = e.detail.object;

    if (e.detail.objectType !== this.props.objectType) {
      return;
    }

    if (object.id && this.props.object.id !== object.id) {
      return;
    }

    if (object.slug && this.props.object.slug !== object.slug) {
      return;
    }

    this.setState({
      voted: _sp.storage.get("vote-" + this.props.objectType + "-" + (this.props.object.slug || this.props.object.id)) === this.props.type
    });
  }

  rateObject() {
    const { object } = this.props;

    _sp.storage.set("vote-" + this.props.objectType + "-" + (object.slug || object.id), this.props.type);
    window.dispatchEvent(new CustomEvent('objectRated', { detail: { objectType: this.props.objectType, object: object } }));

    _sp.axios.get(settings.api_base + "/" + settings.api_version + '/vote/'+ this.props.objectType +'/'+ (object.slug || object.id) +'/' + this.props.type);
  }

  render() {
    let content = <React.Fragment><i className={'far fa-thumbs-up'} /> Like</React.Fragment>;

    switch (this.props.type) {
      case "dislike":
        content = <React.Fragment><i className={'far fa-thumbs-down'} /> Dislike</React.Fragment>;
        break;
    }

    let classes = ["btn"];
    if (this.props.className) {
      classes.push(this.props.className);
    }

    if (this.state.voted) {
      classes.push("btn-danger");
    } else {
      classes.push("btn-default");
    }

    return (
      <button className={classes.join(" ")} onClick={this.rateObject}>{content}</button>
    );
  }
}

export default VoteButton;