import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";

import Loader from "../../shared/Loader";

import {fetchPornstar} from "../../../redux/pornstars";
import HostedImage from "../../shared/HostedImage";
import VideoContainer from "../video/Container";
import GalleryContainer from "../gallery/Container";
import Container from "../image/Container";

class PornstarShow extends Component {
  componentDidMount() {
    this.props.fetchPornstar(this.props.match.params.slug, {
      videoLimit: 8,
      imageLimit: 4,
      preview_format: "horizontal"
    });

    _sp.changeMeta(this.props.pornstar);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    _sp.changeMeta(this.props.pornstar);
  }

  render() {
    const { pornstar } = this.props;

    if (pornstar.loading) {
      return <Loader/>;
    }

    return (
      <div className={'pornstar-show'}>
        <div className={'light-container ' + (pornstar.preview ? 'no-padding' : '')}>
          <div className={'container'}>
            <div className={'row'}>
              <div className={'col-12'}>
                {pornstar.preview ? (
                  <div className={'cover ' + (pornstar.images && pornstar.images.length > 0 ? '' : 'without-image')} style={{ backgroundImage: "url(" + pornstar.preview.sizes.widescreen + ")" }}>
                    {pornstar.images && pornstar.images.length > 0 && <HostedImage image={pornstar.images[0]} />}

                    <h1>{pornstar.name}</h1>
                  </div>
                ) : (
                  <h1>{pornstar.name}</h1>
                )}
              </div>
            </div>
          </div>
        </div>

        {pornstar.videos && pornstar.videos.length > 0 && (
          <div className={'container'}>
            <h3 className={'section-headline'}>Videos</h3>

            <div className={'row'}>
              {pornstar.videos.map((video) => {
                return (
                  <div key={video.slug} className={'col-6 col-lg-3'}>
                    <VideoContainer video={video} />
                  </div>
                )
              })}
            </div>
          </div>
        )}

        {pornstar.galleries && pornstar.galleries.length > 0 && (
          <div className={'container'}>
            <h3 className={'section-headline'}>Galleries</h3>

            <div className={'row full-gutters'}>
              {pornstar.galleries.map((gallery) => {
                return (
                  <div key={gallery.slug} className={'col-6 col-lg-3'}>
                    <GalleryContainer gallery={gallery} />
                  </div>
                )
              })}
            </div>
          </div>
        )}

        {pornstar.images && pornstar.images.length > 0 && (
          <div className={'container'}>
            <h3 className={'section-headline'}>Images</h3>

            <div className={'row full-gutters'}>
              {pornstar.images.map((image) => {
                return (
                  <div key={"image-" + (image.slug || image.id)} className={'col-6 col-lg-3'}>
                    <Container image={image} />
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  if (state.pornstar && state.pornstar.slug === ownProps.match.params.slug) {
    return { pornstar: { loading: false, ...state.pornstar } };
  }

  return { pornstar: { loading: true } };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchPornstar }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PornstarShow));