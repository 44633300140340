import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";

import Loader from "../../shared/Loader";
import Container from "../utility/MixedContainer";
import {fetchImage} from "../../../redux/images";
import {fetchVideo} from "../../../redux/videos";
import HostedImage from "../../shared/HostedImage";
import SwipeArea from "../utility/SwipeArea";
import VideoPlayer from "../utility/VideoPlayer";
import SocialBar from "../utility/SocialBar";
import VoteButton from "../shared/VoteButton";
import ReactionBar from "../shared/ReactionBar";
import NativeAds from "../../shared/ads/NativeAds";
import TagSuggest from "../tag/TagSuggest";
import ShareBar from "../shared/ShareBar";
import SwitchingBanner from "../../shared/ads/SwitchingBanner";

class ImageShow extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    if ((this.props.image || this.props.video).loading) {
      this.loadImage(this.props.match.params.slug, this.props.type);
    }

    this.loadPaging();
    _sp.changeMeta(this.props.image || this.props.video);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.match.params.slug !== this.props.match.params.slug) {
      if ((this.props.image || this.props.video).loading) {
        this.loadImage(this.props.match.params.slug, this.props.type);
      }
      this.loadPaging();
    }
    _sp.changeMeta(this.props.image || this.props.video);
  }

  visitImage(direction) {
    switch (direction) {
      case "next":
        let next = document.getElementById('next-button');
        if (!next) {
          return;
        }

        _sp.visit(next.dataset.href);
        break;
      case "prev":
        let prev = document.getElementById('prev-button');
        if (!prev) {
          return;
        }

        _sp.visit(prev.dataset.href);
        break;
    }
  }

  loadImage(id, type) {
    let tag = _sp.storage.get('image-tag', "session");
    if (!tag || tag === "undefined") {
      tag = "";
    }

    if (type === 'gif' || type === 'Video') {
      return this.props.fetchVideo(id, {
        related: 15,
        tags: tag
      });
    }

    this.props.fetchImage(id, {
      related: 15,
      tags: tag
    }).then(() => {
      window.dispatchEvent(new CustomEvent('preloadData', { detail: { type: "image", id: id }}));
    });
  }

  loadPaging() {
    let type = this.props.type === 'gif' ? 'video' : 'image';
    let sort = _sp.storage.get('image-sort', "session") || "new";
    let tag = _sp.storage.get('image-tag', "session");

    _sp.axios.get(settings.api_base + "/" + settings.api_version + '/' + type + '/' + this.props.match.params.slug + '/pagination', {
      params: {
        sort: sort,
        tags: tag
      }
    }).then((res) => {
      this.setState({ next: res.data.next, prev: res.data.prev }, () => {
        if (this.state.next.id) {
          this.loadImage(this.state.next.id, this.state.next.type)
        }
      });
    })
  }

  render() {
    const { image, video } = this.props;
    const { next, prev} = this.state;

    if ((image && image.loading) || (video && video.loading)) {
      return <Loader/>
    }

    let object = (image || video);
    let related = object.related;

    let type = typeof(image) !== 'undefined' ? 'Image' : 'Video';

    return (
      <div className={'image-show'}>
        <div className={'light-container'}>
          <div className={'container'}>
            <div className={'row'}>
              <div className={'col-12 col-lg-2 order-1 order-lg-0'}>
                <div className={'sidebar inline'}>
                  {object.tags && object.tags.length > 0 && (
                    <React.Fragment>
                      <h3>Tags</h3>

                      <div className={'object-wrapper'}>
                        {object.tags.map((tag) => {
                          return <Link key={Math.random() + tag.slug} to={"/images/category/" + tag.slug} rel={"tag"}>{tag.name}</Link>
                        })}

                        <TagSuggest type={type} slug={object.slug || object.id} />
                      </div>
                    </React.Fragment>
                  )}

                  {object.pornstars && object.pornstars.length > 0 && (
                    <React.Fragment>
                      <h3 className={'mt-3'}>Pornstars</h3>

                      <div className={'object-wrapper'}>
                        {object.pornstars.map((pornstar) => {
                          return <Link key={pornstar.slug} to={"/pornstar/" + pornstar.slug}>{pornstar.name}</Link>
                        })}
                      </div>
                    </React.Fragment>
                  )}
                </div>
              </div>
              <div className={'col-12 col-lg-8 order-0 order-lg-1'}>
                <SwitchingBanner cacheKey={(object.slug || object.id)} desktopBanner={"468x60"} section={'image-show'} />

                <div id={'primary-image'} className={'mt-3 ' + (image ? 'image' : 'video')}>
                  {prev && prev.id && <Link id={"prev-button"} className={'prev'} to={"/" + (prev.type === "Video" ? "gif" : 'image') + "/" + prev.id} data-href={"/" + (prev.type === "Video" ? "gif" : 'image') + "/" + prev.id}><i className={'fas fa-chevron-left'} /></Link>}

                  <SwipeArea onLeft={() => { this.visitImage('prev'); }} onRight={() => { this.visitImage('next'); }} onSwipeLeft={() => { this.visitImage('next') }} onSwipeRight={() => { this.visitImage('prev') }}>
                    {image ? (
                      <HostedImage key={image.slug || image.id} force={true} image={image} size={"full"} showLoader={true} />
                    ) : (
                      <div className={'video-player'} key={object.file}>
                        {object.file ? <VideoPlayer autoplay={true} controls={true} controlState={"hover"} loop={true} sources={[{ src: object.file, type: "video/mp4" }]} /> : <Loader inline={true} />}
                      </div>
                    )}
                  </SwipeArea>

                  <h1 className={'mt-3 mb-3'}>{object.title ? object.title : "Untitled"}</h1>

                  {next && next.id && <Link id={"next-button"} className={'next'} to={"/" + (next.type === "Video" ? "gif" : 'image') + "/" + next.id} data-href={"/" + (next.type === "Video" ? "gif" : 'image') + "/" + next.id}><i className={'fas fa-chevron-right'} /></Link>}
                </div>

                <div className={'row mt-3'} key={type + "-" + (object.slug || object.id)}>
                  <div className={'col-6 col-lg-4 offset-lg-2'}><VoteButton objectType={type} object={object} type={"like"} className={"btn-block"} /></div>
                  <div className={'col-6 col-lg-4'}><VoteButton objectType={type} object={object} type={"dislike"} className={"btn-block"} /></div>
                  <div className={'col-12 col-lg-8 offset-lg-2'}><ReactionBar object={object} objectType={type} /></div>
                  <div className={'col-12 col-lg-8 offset-lg-2'}><ShareBar object={object} /></div>
                  <div className={'col-12 mt-3'}><NativeAds cacheKey={(object.slug || object.id)} type={"image-show"} /></div>
                </div>
              </div>
              <div className={'col-12 col-lg-2 order-2 order-lg-2'}>
                <SocialBar object={object} type={type} className={'mb-3 mt-3'} />

                <h3 className={'section-headline'}>More Images</h3>
                {related && related.length > 0 && (
                  <div className={'row full-gutters'}>
                    {related.slice(0, 3).map((image) => {
                      return (
                        <div className={'col-12 col-sm-4 col-lg-12'} key={image.slug || image.id}>
                          <Container type={type} object={image} size={"thumb"} mixed={false} fullUrl={this.props.type === 'gif' ? `/gif/${image.slug}` : `/image/${(image.slug || image.id)}`} />
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className={'container'}>
          {related && related.length > 3 && (
            <React.Fragment>
              <h3 className={'section-headline mt-4'}>Related Images</h3>
              <div className={'row full-gutters'}>
                {related.slice(3, related.length).map((image) => {
                  return (
                    <div className={'col-6 col-lg-2'} key={image.slug || image.id}>
                      <Container type={type} object={image} fullUrl={this.props.type === 'gif' ? `/gif/${image.slug}` : `/image/${(image.slug || image.id)}`} />
                    </div>
                  );
                })}
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  switch (ownProps.type) {
    case "gif":
      let slug = ownProps.match.params.slug;
      if (state.all && state.all.videos && state.all.videos[slug]) {
        return { video: { loading: false, ...state.all.videos[slug] }};
      }
      break;
    case "image":
      let id = ownProps.match.params.slug;
      if (state.all && state.all.images && state.all.images[id]) {
        return { image: { loading: false, ...state.all.images[id] }};
      }

      id = ownProps.match.params.slug * 1;
      if (state.all && state.all.images && state.all.images[id]) {
        return { image: { loading: false, ...state.all.images[id] }};
      }
      break;
  }

  if (state.image && state.image.id === ownProps.match.params.slug * 1) {
    return { image: { loading: false, ...state.image } };
  }

  if (state.video && state.video.slug === ownProps.match.params.slug) {
    return { video: { loading: false, ...state.video } };
  }

  return { image: { loading: true }, video: { loading: true } };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchImage, fetchVideo }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ImageShow));