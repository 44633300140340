import React, {Component} from 'react';
import PropTypes from 'prop-types';

class BaseList extends Component {
  static defaultProps = {
    sort: "new",
    index: "all",
    limit: 20,
    paginated: false,
    meta: true,
    rowCount: 3,
    filter: {}
  };

  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      loading: false
    };

    this.visitNextPage = this.visitNextPage.bind(this);
  }

  componentDidMount() {
    if (this.props.data.loading) {
      this.loadObjects();
    }

    if (this.props.meta !== false) {
      _sp.changeMeta(this.props.data);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.page < this.state.page) {
      this.loadObjects();
    }

    if (this.props.slug && this.props.slug !== prevProps.slug) {
      this.loadObjects();
    }

    if (prevProps.location && this.props.location && prevProps.location.search !== this.props.location.search) {
      this.setState({ page: 1 }, () => {
        this.loadObjects({ reset: true });
      });
    }

    if (this.props.meta !== false) {
      _sp.changeMeta(this.props.data);
    }
  }

  columnClasses() {
    let classes = ["col-6"];

    let clss = "col-lg-" + (12 / this.props.rowCount);
    clss = clss.replace(".", "-");
    classes.push(clss);

    return classes.join(" ");
  }

  visitNextPage() {
    if (!this._nextPageButton || this._nextPageButton.classList.contains('hidden')) {
      return;
    }

    if (this.props.loading || this.state.loading || !this.props.data.pagination.next_page) {
      return;
    }

    this._nextPageButton.classList.add('hidden');
    this.setState({ loading: true, page: this.props.data.pagination.next_page });
  }

  loadObjects() {
    console.log("NOT IMPLEMENTED");
  }
}

BaseList.propTypes = {
  sort: PropTypes.string,
  index: PropTypes.string,
  paginated: PropTypes.bool,
  meta: PropTypes.bool,
  limit: PropTypes.number,
  rowCount: PropTypes.number,
  filter: PropTypes.object
};

export default BaseList;