import React from "react";
import {Link} from "react-router-dom";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import VisibleEvent from "../../shared/VisibleEvent";
import BaseList from "../utility/BaseList";
import Loader from "../../shared/Loader";
import {fetchGalleries} from "../../../redux/gallery";
import Container from './Container'
import SwitchingBanner from "../../shared/ads/SwitchingBanner";

class List extends BaseList {
  loadObjects() {
    this.props.fetchGalleries({
      sort: this.props.sort,
      index: this.props.index,
      tag: this.props.tag,
      page: this.state.page,
      limit: this.props.limit
    }).then(() => {
      this.setState({ loading: false });
    });
  }

  render () {
    const { data } = this.props;

    if (data.loading) {
      return <Loader inline={true} />;
    }

    return (
      <React.Fragment>
        <div className={'row full-gutters'}>
          {!data.objects || data.pagination.total_count <= 0 ? (
            <div>No Galleries found :(</div>
          ) : (
            data.objects.map((gallery, index) => {
              return (
                <React.Fragment key={gallery.slug}>
                  <div className={this.columnClasses()}>
                    <Container gallery={gallery} />
                  </div>
                  {this.props.ads !== false && index > 0 && (index + 1) % 6 === 0 && <div className={'col-12'}><SwitchingBanner section={'galleries'} /></div>}
                </React.Fragment>
              )
            })
          )}
        </div>
        {data.pagination.next_page && (
          <div className={'text-center mt-3'}>
            {this.props.paginated ? (
              <VisibleEvent key={"page-" + data.pagination.current_page} inViewport={this.visitNextPage} offset={1000}>
                <button ref={(e) => { this._nextPageButton = e; }} onClick={this.visitNextPage} className={'btn btn-default'}>Next Page</button>
              </VisibleEvent>
            ) : (
              <Link to={"/galleries"} className={'btn btn-default scroll-top'}>View more</Link>
            )}
          </div>
        )}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state, ownProps) {
  if (!state.galleries[ownProps.index]) {
    return { data: { loading: true } };
  }

  return { data: state.galleries[ownProps.index] };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchGalleries }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(List);