export const fetchImages = (opts={}) => (dispatch) => {
  let sort = opts.sort || "new";
  let index = opts.index || "all";
  let limit = opts.limit || 20;
  let page = opts.page || 1;
  let tag = opts.tag;
  let format = opts.format;
  let after = opts.after;
  let display_tags = opts.display_tags ? "1" : "0";

  return _sp.axios.get(settings.api_base + "/" + settings.api_version + '/image', {
    params: {
      tags: tag,
      sort: sort,
      limit: limit,
      image_format: format,
      page: page,
      after: after,
      indexes: index,
      display_tags: display_tags
    }
  })
    .then(res => {
      return res.data;
    })
    .then(data => {
      dispatch({
        type: "IMAGES_LOADED",
        data: data,
        index: index,
        reset: opts.reset || false
      });
    })
};

export const imagesReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case "IMAGES_LOADED":
      if (!action.data.objects) {
        action.data.objects = [];
      }

      let existing = state[action.index];
      if (action.reset) {
        existing = undefined;
      }

      let objects = (existing ? existing.objects : []).concat(action.data.objects);

      return Object.assign({}, state, {
        [`${action.index}`]: {
          loading: false,
          objects: objects,
          meta_tags: action.data.meta_tags,
          pagination: action.data.pagination,
          util: action.data.util
        }
      });
    default:
      return state;
  }
};

export const fetchImage = (id, opts={}) => (dispatch) => {
  return _sp.axios.get(settings.api_base + "/" + settings.api_version + '/image/' + id, {
    params: opts
  })
    .then(res => {
      return res.data;
    })
    .then(data => {
      dispatch({
        type: "IMAGE_LOADED",
        data: data
      });
    })
};

export const imageReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case "IMAGE_LOADED":
      return action.data;
    default:
      return state;
  }
};