import React, {Component} from 'react';

class NativeAds extends Component {
  constructor(props) {
    super(props);

    let idMapping = {
      "default": { idzone: "3614193" }, // 3401999
      "image-show": { idzone: "3614187" }, // 3451695
      "gallery-show": { idzone: "3608809" }, // 3451687
      "gallery-image": { idzone: "3608809" }, // 3451687
      "video-show": { idzone: "3614189" } // 3402037
    };

    let zone = idMapping[this.props.type];

    this.state = {
      idzone: zone ? zone.idzone : idMapping["default"].idzone
    }
  }

  componentDidMount() {
    _sp.inject('https://ads.exosrv.com/nativeads.js', 'js', { append: true, data: { idzone: this.state.idzone }, callback: (e) => { e.target.remove(); } });
  }


  render() {
    return (
      <div id={"native-test"} data-idzone={this.state.idzone} />
    );
  }
}

export default NativeAds;