import React from "react"
import PropTypes from "prop-types"
import { Link } from 'react-router-dom';

import HostedImage from "../../shared/HostedImage";
import PreviewVideo from "./PreviewVideo";
import ReactionCounts from "../shared/ReactionCounts";

class Container extends React.Component {
  static defaultProps = {
    mixed: false,
    title: true
  };

  constructor(props) {
    super(props);

    this.state = {
      imageIndex: -1,
      hasTrailer: typeof(props.video.trailer) === "string",
      hovered: false,
      cycled: false
    };

    this.onHover = this.onHover.bind(this);
    this.onLeave = this.onLeave.bind(this);
  }

  componentDidMount() {
    this._container.addEventListener('mouseover', this.onHover);
    this._container.addEventListener('mouseout', this.onLeave);
    this.preload(0);
  }

  componentWillUnmount() {
    this._container.removeEventListener('mouseover', this.onHover);
    this._container.removeEventListener('mouseout', this.onLeave);
    clearTimeout(this._timeout);
  }

  onHover() {
    this.setState({ hovered: true });
    if (!this.state.hasTrailer) {
      this.displayNextImage();
    }
  }

  onLeave() {
    clearTimeout(this._timeout);
    this.setState({ imageIndex: -1, hovered: false });
  }

  displayNextImage() {
    let nextIndex = this.state.imageIndex + 1;
    if (!this.props.video.images[nextIndex]) {
      nextIndex = 0;
    }

    this.preload(nextIndex);

    this._timeout = setTimeout(() => {
      this.setState({ imageIndex: nextIndex, cycled: true }, () => {
        this.displayNextImage();
      });
    }, 750)
  }

  preload(index) {
    let image = this.props.video.images[index];
    if (!image) {
      return;
    }

    let i = new Image();
    i.src = (image.url || image.sizes.small_square).replace("http:", "https:");
  }

  formatDuration(time) {
    let seconds = Math.round(time % 60);
    let minutes = Math.floor(time / 60);
    let hours   = Math.floor(minutes / 60);

    let data = [
      (hours > 0 ? hours % 24 : undefined),
      minutes % 60,
      seconds
    ];

    return data.filter(t => t !== undefined).map(i => {
      return (i < 10 ? ("0" + i) : i)
    }).join(":");
  }

  render () {
    const { video } = this.props;

    let currentImage = this.state.imageIndex < 0 ? video.images[Math.floor(video.images.length / 2)] : video.images[this.state.imageIndex];

    return (
      <article className={'object-container'} ref={(node) => { this._container = node; }}>
        <Link to={`${this.props.fullUrl || '/video/' + video.slug}`} className={'image-container'} title={video.title}>
          <HostedImage image={currentImage} force={this.state.cycled} className={'img-fluid'} alt={video.title} mixed={this.props.mixed} size={'large'} />
          {this.props.mixed && <i className={'fas fa-play icon'} />}
          {this.state.hasTrailer && <PreviewVideo video={video} visible={this.state.hovered} />}
          <ReactionCounts object={video} />
        </Link>
        {(!this.props.mixed || this.props.title) && (
          <div className={'info-row'}>
            <div className={'utility'}>
              {video.initial_views * 1 > 0 && <React.Fragment><i className={'far fa-play'} /> {(video.initial_views * 1).toLocaleString()}</React.Fragment>}
              <i className={'far fa-clock'} /> {this.formatDuration(video.duration)}
            </div>
            <h3><Link to={`/video/${video.slug}`} title={video.title}>{video.title}</Link></h3>
          </div>
        )}
      </article>
    );
  }
}

Container.propTypes = {
  video: PropTypes.object,
  mixed: PropTypes.bool,
  title: PropTypes.bool
};
export default Container
