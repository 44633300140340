import React from "react";
import {Link} from "react-router-dom";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import VisibleEvent from "../../shared/VisibleEvent";
import BaseList from "../utility/BaseList";
import Loader from "../../shared/Loader";
import {fetchCams} from "../../../redux/cams";
import Container from './Container'

class List extends BaseList {
  loadObjects() {
    this.props.fetchCams({
      sort: this.props.sort,
      index: this.props.index,
      tag: this.props.tag,
      page: this.state.page,
      limit: this.props.limit
    }).then(() => {
      this.setState({ loading: false });
    });
  }

  render () {
    const { data } = this.props;

    if (data.loading) {
      return <Loader inline={true} />;
    }

    return (
      <React.Fragment>
        <div className={'row full-gutters'}>
          {!data.objects || data.pagination.total_count <= 0 ? (
            <div>No Cams found :(</div>
          ) : (
            data.objects.map((cam) => {
              return (
                <div key={cam.slug} className={'col-6 col-lg-4'}>
                  <Container cam={cam} />
                </div>
              )
            })
          )}
        </div>
        {data.pagination.next_page && (
          <div className={'text-center mt-3'}>
            {this.props.paginated ? (
              <VisibleEvent key={"page-" + data.pagination.current_page} inViewport={this.visitNextPage} offset={1000}>
                <button ref={(e) => { this._nextPageButton = e; }} onClick={this.visitNextPage} className={'btn btn-default'}>Next Page</button>
              </VisibleEvent>
            ) : (
              <Link to={"/galleries"} className={'btn btn-default scroll-top'}>View more</Link>
            )}
          </div>
        )}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state, ownProps) {
  if (!state.cams[ownProps.index]) {
    return { data: { loading: true } };
  }

  /*if (expired(state.videos[ownProps.index].util)) {
    return { data: { loading: true } };
  }*/

  return { data: state.cams[ownProps.index] };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchCams }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(List);