import React, {Component} from 'react';

class Contact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: {},
      token: undefined
    };

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);

    this.verifyCallback = this.verifyCallback.bind(this);
  }

  componentDidMount() {
    _sp.inject("https://www.google.com/recaptcha/api.js", "js");
    this.toggleVisibility(this._type)
  }

  onSelectChange(e) {
    this.toggleVisibility(e.target);
  }

  onFormSubmit(e) {
    e.preventDefault();

    _sp.axios.post('/contact', {
      email: this._email.value,
      type: this._type.options[this._type.selectedIndex].value,
      username: this._username.value,
      url: this._url.value,
      message: this._message.value,
      gtoken: document.getElementById('g-recaptcha-response').value
    }).then(res => {
      if (res.data.error) {
        this.setState({ errors: res.data.errors });
      } else {
        this.setState({ errors: {}, success: true });
      }
    });
  }

  verifyCallback(recaptchaToken) {
    this.setState({ token: recaptchaToken })
    // Here you will get the final recaptchaToken!!!
    console.log(recaptchaToken, "<= your recaptcha token")
  }

  toggleVisibility(elem) {
    let type = elem.options[elem.selectedIndex].value;
    if (type === "report" || type === "dmca") {
      document.getElementById('url-group').classList.remove("hidden")
    } else {
      document.getElementById('url-group').classList.add("hidden")
    }
  }

  render() {
    const { errors } = this.state;

    if (this.state.success) {
      return (
        <div className={'container'}>
          <h1 className={'section-headline'}>Contact</h1>

          <h4>Your contact was submitted successfully</h4>
        </div>
      )
    }

    return (
      <div className={'container'}>
        <h1 className={'section-headline'}>Contact</h1>

        <form onSubmit={this.onFormSubmit}>
          <div className="form-group">
            <label htmlFor="email">E-Mail-Address*</label>
            {errors && errors.email && <div className={'errors'}>{errors.email.join(", ")}</div>}
            <input type={'email'} name={'email'} className={'form-control'} ref={(e) => this._email = e} />
          </div>
          <div className="form-group">
            <label htmlFor="type">Type of contact</label>
            <select name={'type'} defaultValue={this.props.type} className={'form-control'} onChange={this.onSelectChange} ref={(e) => this._type = e}>
              <option value={'default'}>Global</option>
              <option value={'report'}>Report</option>
              <option value={'advertisement'}>Advertisement</option>
              <option value={'dmca'}>DMCA</option>
            </select>
          </div>
          <div className="form-group hidden" id={'url-group'}>
            <label htmlFor="url">URL to the content*</label>
            <input type={'text'} name={'url'} className={'form-control'} ref={(e) => this._url = e} />
          </div>
          <div className="form-group">
            <label htmlFor="message">Your message*</label>
            {errors && errors.message && <div className={'errors'}>{errors.message.join(", ")}</div>}
            <textarea className={'form-control'} name={'message'} rows={4} ref={(e) => this._message = e} />
          </div>

          <input type={'hidden'} name={'username'} ref={(e) => this._username = e} />

          <div className="g-recaptcha" data-sitekey="6LeQAqAUAAAAALBRZViLykGIum_lYMX956lkGmwM" data-theme="dark" />

          <button className={'btn btn-default'}>Submit</button>
        </form>
      </div>
    );
  }
}

export default Contact;