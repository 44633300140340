import React from "react"
import { Link } from "react-router-dom";
import {connect} from "react-redux";

import TopicList from "../topic/List";
import TagList from "../tag/List";
import List from "../video/List";
import ImageList from "../image/List";
import GalleryList from "../gallery/List";
import MixedList from "../shared/MixedList";
import FeatureArea from "../layout/FeatureArea";
import Slider from "../pornstar/Slider";

class Home extends React.Component {
  componentDidMount() {
    if (this.props.meta && this.props.meta.default) {
      _sp.changeMeta(this.props.meta.default)
    }
  }

  render () {
    const { config } = this.props;

    return (
      <React.Fragment>
        <FeatureArea title={config.page_title} />

        <div className={'container'}>
          <div className={'row'}>
            <div className={'col-12'}>
              <h2 className="section-headline"><i className={'fas fa-fire'} /> Popular {config.keyword} Porn</h2>
              <MixedList sort={"popular"} index={"popular"} limit={12} meta={false} rowCount={4} />

              <h2 className="section-headline"><i className={'far fa-images'} /> New {config.keyword} Images</h2>
              <ImageList sort={"new"} index={"new"} limit={12} meta={false} rowCount={4} mixed={true} ads={false} />

              <h2 className="section-headline"><i className={'far fa-video'} /> New {config.keyword} Videos</h2>
              <List sort={"new"} index={"new"} limit={12} meta={false} rowCount={4} filter={{ views: 1000, duration: 900 }} ads={false} />

              <h2 className="section-headline"><i className={'far fa-images'} /> New {config.keyword} Galleries</h2>
              <GalleryList sort={"new"} index={"new"} limit={12} meta={false} rowCount={4} ads={false} />
              <hr />

              <div className={'light-container outer-padding'}>
                <h2 className={'text-center mb-3'}>Popular Pornstars</h2>

                <Slider />

                <div className={'text-center mt-3'}><Link to={"/pornstars"} className={'btn btn-default'}>View all</Link></div>
              </div>

              <h3 className={'section-headline'}>Popular Tags</h3>
              <TagList index={"home"} limit={8} meta={false} rowCount={4} sort={"hot"} />

              <h2 className="section-headline">Popular {config.keyword} Topics</h2>
              <TopicList limit={12} meta={false} rowCount={4} />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return { config: state.config, meta: state.all.meta };
}

export default connect(mapStateToProps)(Home)