import React, {Component} from 'react';

class TermsShow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageTitle: ""
    }
  }

  componentDidMount() {
    this.setState({
      pageTitle: settings.site_title
    })
  }

  render() {
    return (
      <div className={'container'}>
        <h1 className={'section-headline'}>Terms of Service</h1>
        We strive to uphold the ideals of freedom of speech on this website. However, to stay within the confines of applicable law, there are certain rules you must obey with content you upload to this site. What you can and cannot post is listed below. If what you wish to post is not listed, you can assume that this isn't the place to post it.
        <br /><br />
        You may:
        <br /><br />
        - Post real high-quality cosplay and porn that complies with US and international law.
        <br /><br />
        You may NOT:
        <br /><br />
        - Post any kind of material that contains or depicts people who are or appear to be underage (even if they are borderline), nor galleries that claim to contain this kind of content (using keywords like "lolita", "preteen", "incest", "lolicon" or derivatives, even if they do not contain this). This includes drawings and underage "models" who do not engage in actual sexual activity, as well as normal photos involving children. ANY KIND OF ILLICIT ACTIVITY INVOLVING CHILDREN WILL BE REPORTED ALONG WITH A FULL TRACE OF ALL YOUR ACTIVITY ON THIS SITE.
        <br /><br />
        - Post photograpic material of real people that can be considered "obscene". This includes extreme BDSM, bestiality, scat, and other stuff that is way "out there". This isn't where to come for that stuff, please go away.
        <br /><br />
        - Post any kind of drawn or virtual material that violates the principles above. This explicitly includes, but is not limited to, computer-generated images ("rendered") and images created based on real people or pictures ("rotoscoped"). If it resembles or was obviously manufactured to resemble the real thing, this rule particularly applies.
        <br /><br />
        - ANY KIND OF MATERIAL INVOLVING CHILDREN IS ABSOLUTELY PROHIBITED, EVEN IF THEY ARE NOT PORNOGRAPHIC OR RISQUE IN NATURE, AND EVEN IF THEY CONTAIN NO NUDITY NOR POSES WITH SEXUAL IMPLICATIONS. JUST A SINGLE VIOLATION OF THIS RULE WILL CAUSE YOUR ACCOUNT TO BE PERMANENTLY BANNED, AND IF THE MATERIAL IS ILLICIT, YOUR DETAILS TO BE HANDED OVER TO THE AUTHORITIES IN YOUR JURISDICTION.
        <br /><br />
        - Computer-generated or "virtual" child pornography is just as prohibited as the real stuff. Uploading this type of images is a one-strike-and-you're-out infraction.

        <h3 className={'section-headline'}>Posting Guidelines</h3>
        Activity that violates one or more of the following guidelines may cause your galleries, images and videos to be expunged, and your account to be temporarily or permanently blocked from posting galleries.
        <br /><br />
        - Compilations of more than one "work" is not allowed. This does not include combining selections of images into one Image Set gallery.
        <br /><br />
        - Avoid posting galleries with just a few images unless it is naturally released as such. "A few" should generally be interpreted as less than eight.

        <h3 className={'section-headline'}>Content Licensing</h3>
        You agree that, upon uploading content to this site, the content you upload is either Public Domain, released under a Creative Commons-type license, or not copyrighted (i.e. it is within your rights to publish it here); or you own the copyright to the content and consent to it being fully published here; or you have a separate license from the copyright holder to make their content publicly available.
        <br /><br />
        You retain all of your ownership rights for your content. However, by submitting content to {this.state.pageTitle}, you hereby grant {this.state.pageTitle} a worldwide, non-exclusive, royalty-free, perpetual and irrevocable license to use, reproduce, distribute, prepare derivative works of, display, and perform the content in connection with the service and {this.state.pageTitle}'s (and its successors' and affiliates') business, including without limitation for promoting and redistributing part or all of the service (and derivative works thereof) in any media formats and through any media channels. You also hereby grant each user of the service a non-exclusive license to access your content through the service, and to use, reproduce, distribute, display and perform such content as permitted through the functionality of the service and under these terms. The above licenses granted by you are perpetual and irrevocable.
        <br /><br />
        IF YOU ARE NOT LEGALLY ENTITLED TO GRANT THESE LICENSES FOR THE UPLOADED CONTENT, THEN YOU ARE NOT PERMITTED TO UPLOAD THE CONTENT TO {this.state.pageTitle} GALLERIES OR OTHER SERVICES PROVIDED BY {this.state.pageTitle}. YOU RETAIN FULL LIABILITY FOR ANY MISAPPROPRIATED CONTENT UPLOADED TO THIS SERVICE. REPEAT INFRINGERS WILL BE TERMINATED OR HAVE THEIR UPLOADING PRIVILEGES REVOKED AT OUR DISCRETION.

        <h3 className={'section-headline'}>Copyright and Intellectual Property</h3>
        {this.state.pageTitle} Galleries complies with applicable copyright law. Unfortunately, due to sheer volume it is impossible for us to verify the copyright status of every single gallery uploaded to our site, nor are we legally complied to do so. If you find that your copyright has been infringed by one of our members, please report this using the DMCA Report Form found by clicking the Report button on the detail page, by sending an email to simplyporncom (at) gmail (DOT) com, or by using the contact form in the footer. Please make sure to include all the information below.
        <br /><br />
        A properly filed takedown notice needs the following information to be valid. You are hereby made aware that if any of the legally required information is missing or invalid, we cannot be legally considered to have actual knowledge of copyright infringement.
        <br /><br />
        - A physical or electronic signature of a person (full legal name) authorized to act on behalf of the owner of an exclusive right that is allegedly infringed;
        <br /><br />
        - Identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works at a single online site are covered by a single notification, a representative list of such works at that site;
        <br /><br />
        - Identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled and information reasonably sufficient to permit the service provider to locate the material;
        <br /><br />
        - Information reasonably sufficient to permit the service provider to contact you, such as an address, telephone number, and, if available, an electronic mail;
        <br /><br />
        - A statement that you have a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law; and
        <br /><br />
        - A statement that the information in the notification is accurate, and under penalty of perjury, that you are authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.
        <br /><br />
        You may send it to the specified email address in the form of a DMCA Takedown Notice if you wish, as it contains sufficient provisions. Normally we will take action within the hour, but please allow six to twelve hours in case there is no qualified staff available to process your claim. Note that we will not generally respond to reports unless we require addtional information.
        <br /><br />
        Please note that using the report button or sending an email to us directly is much faster than whining to our hosts. They are well informed of our takedown procedures, and they will simply forward the complaint to us with a day or two delay for their own processing.
        <br /><br />
        Furthermore, please be aware that all copyright claims are made under perjury of law, and false claims may be prosecuted.
        <br /><br />
      </div>
    );
  }
}

export default TermsShow;