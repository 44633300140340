import React, {Component} from 'react';
import { withRouter } from "react-router-dom";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import { fetchFeatured } from "../../../redux/featured";
import Loader from "../../shared/Loader";
import MixedContainer from "../utility/MixedContainer";
import SwitchingBanner from "../../shared/ads/SwitchingBanner";

class FeatureArea extends Component {
  static defaultProps = {
    type: "all",
    index: "all",
    sort: "new"
  };

  componentDidMount() {
    /*
    { type: this.props.type, tag: this.props.tag }
     */

    if (this.props.data.loading) {
      this.loadContent();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.index !== this.props.index || prevProps.type !== this.props.type || prevProps.tag !== this.props.tag) {
      this.loadContent();
    }
  }

  loadContent() {
    this.props.fetchFeatured({
      limit: 4,
      tag: this.props.tag,
      type: this.props.type,
      index: this.props.index || "all",
      sort: this.props.sort || "new",
      filters: {
        views: 10000,
        duration: 900
      }
    });
  }

  render() {
    const { data } = this.props;

    return (
      <div className={'light-container'}>
        <div className={'container text-center'}>
          {this.props.title && <h1 className={'up mb-3'}>{this.props.title}</h1>}

          {data.loading ? (
            <Loader inline={true} />
          ) : (
            <div className={'row full-gutters'}>
              {(data.objects || []).map((object) => {
                return (
                  <div key={(object.slug || object.id)} className={'col-6 col-lg-3'}>
                    <MixedContainer object={object} />
                  </div>
                )
              })}
            </div>
          )}
        </div>
        <SwitchingBanner className={"mt-2"} cacheKey={this.props.tag + this.props.type + this.props.index + this.props.sort} />
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  let index = (ownProps.index || "all") + "-" + (ownProps.type || "all");

  if (state.featured && state.featured[index]) {
    return { data: state.featured[index] };
  }

  return { data: { loading: true } };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchFeatured }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FeatureArea));