import React, {Component} from 'react';
import Overlay from "../../porn_app/utility/Overlay";
import SocialLogin from "./SocialLogin";

class SignIn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      myKB: false,
      visible: false,
      error: false,
    };

    this.onShow = this.onShow.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onSignIn = this.onSignIn.bind(this);
    this.onSignUpClick = this.onSignUpClick.bind(this);
    this.onLostPasswordClick = this.onLostPasswordClick.bind(this);
  }

  componentDidMount() {
    window.addEventListener('showSignIn', this.onShow)

    if (_sp.dom.getParameterByName('sign_in') === "1") {
      this.setState({ visible: true });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('showSignIn', this.onShow)
  }

  onShow(e) {
    if (e.detail && e.detail.return_url) {
      this.setState({ return_url: e.detail.return_url })
    }

    this.setState({ visible: true });
  }

  onClose() {
    this.setState({ visible: false });
  }

  onSignIn(e) {
    e.preventDefault();

    let email = this._form.querySelector('[name="email"]').value;
    let password = this._form.querySelector('[name="password"]').value;

    this.setState({ error: false }, () => {
      _sp.user.signIn(email, password)
        .then(() => {
          this.setState({ visible: false }, () => {
            let url = _sp.dom.getParameterByName('return_url') || this.state.return_url;

            if (url) {
              _sp.visit(url);
            }
          });
        })
        .catch(error => {
          this.setState({ error: true });
        });
    });
  }

  onLostPasswordClick() {
    this.setState({ visible: false });
    window.dispatchEvent(new Event('showLostPassword'));
  }

  onSignUpClick() {
    this.setState({ visible: false });
    window.dispatchEvent(new Event('showSignUp'));
  }

  render() {
    if (!this.state.visible) {
      return "";
    }

    return (
      <Overlay clickCallback={this.onClose}>
        <div className={'content-overlay-body sign-in'}>
          <button className={'close-button'} alt={"Close"} onClick={this.onClose}><i className={'far fa-times'} /></button>
          <h2 className={'page-headline text-center'}>Sign In</h2>

          <h3 className={'content-headline text-center'}>Social Login</h3>

          <div className={'row no-gutters social-logins'}>
            <div className={'col-6 col-md-5 offset-md-1 col-lg-3 offset-lg-3 col-xl-2 offset-xl-4'}><SocialLogin provider={"google"} /></div>
            <div className={'col-6 col-md-5 col-lg-3 col-xl-2'}><SocialLogin provider={"facebook"} /></div>
            <div className={'col-6 col-md-5 offset-md-1 col-lg-3 offset-lg-3 col-xl-2 offset-xl-4'}><SocialLogin provider={"twitter"} /></div>
            <div className={'col-6 col-md-5 col-lg-3 col-xl-2'}><SocialLogin provider={"discord"} /></div>
          </div>

          <h3 className={'content-headline text-center'}>Login with E-Mail</h3>

          <form onSubmit={this.onSignIn} ref={(e) => { this._form = e; }}>
            {this.state.error && <div className={'errors'}>Invalid E-Mail or Password</div>}

            <div className={'row'}>
              <div className={'col-12 col-xl-6'}>
                <label htmlFor="email">E-Mail-Address</label>
                <input type={'text'} name={'email'} className={'form-control'} />
              </div>
              <div className={'col-12 col-xl-6'}>
                <label htmlFor="password">Password</label>
                <input type={'password'} name={'password'} className={'form-control'} />
              </div>
            </div>

            <div className={'row mt-3'}>
              <div className={'col-12 col-lg-6 offset-lg-3'}>
                <button className={'btn btn-danger btn-block'} alt={"Login"}>Login</button>
                <button onClick={this.onSignUpClick} className={'btn btn-default btn-block'} alt={"Create new Account"}>Create new Account</button>
                <button onClick={this.onLostPasswordClick} className={'btn btn-default btn-block'} alt={"Forgot Password"}>Forgot Password?</button>
              </div>
            </div>
          </form>
        </div>
      </Overlay>
    );
  }
}

export default SignIn;