import React, {Component} from 'react';

class ReactionBar extends Component {
  constructor(props) {
    super(props);

    this.onReact = this.onReact.bind(this);
  }

  reactionCount(reaction) {
    const { object } = this.props;
    let count = object.reactions[reaction] || 0;
    if (count > 99) {
      return "99+";
    }
    return count;
  }

  onReact(e) {
    const { object } = this.props;

    let target = e.currentTarget;

    _sp.axios.get(settings.api_base + "/" + settings.api_version + '/react/'+ this.props.objectType +'/'+ (object.slug || object.id) +'/' + target.dataset.type).then((res) => {
      target.querySelector('.reaction-count').innerHTML = res.data.count;
    });
  }

  render() {
    return (
      <div className={'reaction-bar'}>
        {["hot", "tits", "fapped", "pussy", "ass", "bigass", "doggy", "meh", "puke"].map((reaction) => {
          return (
            <button key={reaction} onClick={this.onReact} data-type={reaction}>
              <img className={'reaction-' + reaction} alt="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABAAQMAAACQp+OdAAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAAA9JREFUeNpjYBgFo4B8AAACQAAB4E0dRAAAAABJRU5ErkJggg==" />
              <div className="reaction-count">{this.reactionCount(reaction)}</div>
            </button>
          )
        })}
      </div>
    );
  }
}

export default ReactionBar;