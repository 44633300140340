import React from 'react';
import {bindActionCreators} from "redux";
import {Link} from "react-router-dom";

import {fetchImages} from "../../../redux/images";
import {connect} from "react-redux";
import BaseList from "../utility/BaseList";
import Loader from "../../shared/Loader";
import Container from "./Container";
import VisibleEvent from '../../shared/VisibleEvent'
import SwitchingBanner from "../../shared/ads/SwitchingBanner";

class List extends BaseList {
  loadObjects() {
    this.props.fetchImages({
      sort: this.props.sort,
      index: this.props.index,
      tag: this.props.tag,
      page: this.state.page,
      limit: this.props.limit
    }).then(() => {
      this.setState({ loading: false });
    });
  }

  render() {
    const { data } = this.props;

    if (data.loading) {
      return <Loader inline={true} />;
    }

    return (
      <React.Fragment>
        <div className={'row full-gutters'}>
          {!data.objects || data.pagination.total_count <= 0 ? (
            <div>No Images found :(</div>
          ) : (
            data.objects.map((object, index) => {
              return (
                <React.Fragment key={object.slug || object.id}>
                  <div className={this.columnClasses()}>
                    <Container image={object} mixed={this.props.mixed || false} />
                  </div>
                  {this.props.ads !== false && index > 0 && (index + 1) % 6 === 0 && <div className={'col-12'}><SwitchingBanner section={'images'} /></div>}
                </React.Fragment>
              )
            })
          )}
        </div>
        {data.pagination.next_page && (
          <div className={'text-center mt-3'}>
            {this.props.paginated ? (
              <VisibleEvent key={"page-" + data.pagination.current_page} inViewport={this.visitNextPage} offset={1000}>
                <button ref={(e) => { this._nextPageButton = e; }} onClick={this.visitNextPage} className={'btn btn-default'}>Next Page</button>
              </VisibleEvent>
            ) : (
              <Link to={"/images"} className={'btn btn-default scroll-top'}>View more</Link>
            )}
          </div>
        )}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state, ownProps) {
  if (!state.images[ownProps.index]) {
    return { data: { loading: true } };
  }

  return { data: state.images[ownProps.index] };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchImages }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(List);