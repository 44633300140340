import React, {Component} from 'react';
import {Link} from "react-router-dom";
import HostedImage from "../../shared/HostedImage";

class Container extends Component {
  render() {
    const { tag } = this.props;

    return (
      <article className={'object-container'}>
        <Link to={"/tag/" + tag.slug} className={'image-container'} title={tag.name}>
          <HostedImage image={tag.preview} className={'img-fluid'} alt={tag.name} mixed={true} />
        </Link>
        <div className={'info-row'}>
          <div className={'utility'}>
            <i className={'far fa-image'} /> {tag.object_count.toLocaleString()}
          </div>

          <h3><Link to={`/tag/${tag.slug}`} title={tag.name}>{tag.name}</Link></h3>
        </div>
      </article>
    );
  }
}

export default Container;