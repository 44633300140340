import React, {Component} from 'react';
import PropTypes from 'prop-types';

class SwipeArea extends Component {
  constructor(props) {
    super(props);

    this.state = {
      key: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
    };

    this._hammer = undefined;

    this.onWindowSwipe = this.onWindowSwipe.bind(this);
    this.onWindowKeyDown = this.onWindowKeyDown.bind(this);
  }

  componentDidMount() {
    window.addEventListener('keydown', this.onWindowKeyDown);

    let elem = document.getElementById(this.state.key);
    if (!elem) {
      return;
    }

    this._hammer = new Hammer(elem, {
      recognizers: [
        [Hammer.Swipe,{ direction: Hammer.DIRECTION_HORIZONTAL }],
      ]
    });

    this._hammer.on("swipe", this.onWindowSwipe);
  }

  componentWillUnmount() {
    if (this._hammer) {
      this._hammer.off("swipe", this.onWindowSwipe);
    }

    window.removeEventListener('keydown', this.onWindowKeyDown);
  }

  onWindowSwipe(e) {
    switch (e.direction) {
      case Hammer.DIRECTION_LEFT:
        // this.visitImage("next");
        if (typeof(this.props.onSwipeLeft) === 'function') {
          this.props.onSwipeLeft();
        }
        break;
      case Hammer.DIRECTION_RIGHT:
        // this.visitImage("prev");
        if (typeof(this.props.onSwipeRight) === 'function') {
          this.props.onSwipeRight();
        }
        break;
    }
  }

  onWindowKeyDown(e) {
    switch (e.key) {
      case "ArrowLeft":
        if (typeof(this.props.onLeft) === 'function') {
          this.props.onLeft();
        }
        break;
      case "ArrowRight":
        if (typeof(this.props.onRight) === 'function') {
          this.props.onRight();
        }
        break;
    }
  }

  render() {
    return (
      <div id={this.state.key} className={'swipe-area'}>
        {this.props.children}
      </div>
    );
  }
}

SwipeArea.propTypes = {
  onLeft: PropTypes.func,
  onRight: PropTypes.func,
  onTop: PropTypes.func,
  onBottom: PropTypes.func,
  onSwipeLeft: PropTypes.func,
  onSwipeRight: PropTypes.func,
  onSwipeTop: PropTypes.func,
  onSwipeBottom: PropTypes.func,
  onSwipe: PropTypes.func,
};

export default SwipeArea;