import React, {Component} from 'react';
import Banner from "./Banner";
import PropTypes from 'prop-types';

class SwitchingBanner extends Component {
  constructor(props) {
    super(props);

    this.state = {
      device: undefined
    };

    this.onResize = this.onResize.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  onResize() {
    if (typeof(window) !== "object") {
      return;
    }

    let device = "mobile";

    if (window.innerWidth > 575) {
      device = "tablet";
    }

    if (window.innerWidth > 991) {
      device = "desktop";
    }

    if (this.state.device === device) {
      return;
    }

    this.setState({
      device: device
    });
  }

  render() {
    if (!this.state.device) {
      return "";
    }

    if (this.state.device === "desktop") {
      return <Banner section={this.props.section} type={this.props.type} size={this.props.desktopBanner || "728x90"} device={this.state.device} cacheKey={this.props.cacheKey} className={this.props.className} />
    }

    if (this.state.device === "tablet") {
      return <Banner section={this.props.section} type={this.props.type} size={this.props.tabletBanner || "468x60"} device={this.state.device} cacheKey={this.props.cacheKey} className={this.props.className} />
    }

    return (
      <Banner section={this.props.section} type={this.props.type} size={this.props.mobileBanner || "300x100"} device={this.state.device} cacheKey={this.props.cacheKey} className={this.props.className} />
    );
  }
}

SwitchingBanner.propTypes = {
  section: PropTypes.string,
  cacheKey: PropTypes.string,
  desktopBanner: PropTypes.string,
  tabletBanner: PropTypes.string,
  mobileBanner: PropTypes.string,
  className: PropTypes.string
};

export default SwitchingBanner;