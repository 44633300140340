import React, {Component} from 'react';
import {Link, withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import {fetchGallery} from "../../../redux/gallery";
import Container from '../gallery/Container'
import Loader from "../../shared/Loader";
import HostedImage from "../../shared/HostedImage";
import SocialBar from "../utility/SocialBar";
import VoteButton from "../shared/VoteButton";
import ReactionBar from "../shared/ReactionBar";
import TagSuggest from "../tag/TagSuggest";
import ShareBar from "../shared/ShareBar";
import SwitchingBanner from "../../shared/ads/SwitchingBanner";
import NativeAds from "../../shared/ads/NativeAds";

class GalleryShow extends Component {
  componentDidMount() {
    if (this.props.gallery.loading) {
      this.loadGallery();
    }

    _sp.changeMeta(this.props.gallery);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.match.params.slug !== this.props.match.params.slug) {
      this.loadGallery();
    }

    _sp.changeMeta(this.props.gallery);
  }

  loadGallery() {
    this.props.fetchGallery(this.props.match.params.slug, {
      related: 4
    });
  }

  render() {
    const { gallery } = this.props;

    if (gallery.loading) {
      return <Loader/>
    }

    let related = gallery.related;

    return (
      <div className={'gallery-show'}>
        <div className={'light-container'}>
          <div className={'container'}>
            <h1 className={'mt-3 mb-3'}>{gallery.title}</h1>

            <div className={'row'}>
              <div className={'col-12 col-lg-2 order-1 order-lg-0'}>
                <div className={'sidebar inline'}>
                  {gallery.tags && gallery.tags.length > 0 && (
                    <React.Fragment>
                      <h3>Tags</h3>

                      <div className={'object-wrapper'}>
                        {gallery.tags.map((tag) => {
                          return <Link key={tag.slug} to={"/galleries/category/" + tag.slug} rel={"tag"}>{tag.name}</Link>
                        })}

                        <TagSuggest type={"gallery"} slug={gallery.slug} />
                      </div>
                    </React.Fragment>
                  )}

                  {gallery.pornstars && gallery.pornstars.length > 0 && (
                    <React.Fragment>
                      <h3 className={'mt-3'}>Pornstars</h3>

                      <div className={'object-wrapper'}>
                        {gallery.pornstars.map((pornstar) => {
                          return <Link key={pornstar.slug} to={"/pornstar/" + pornstar.slug}>{pornstar.name}</Link>
                        })}
                      </div>
                    </React.Fragment>
                  )}
                </div>

                <SocialBar object={gallery} type={'gallery'} className={"mt-3"} />
              </div>
              <div className={'col-12 col-lg-8 order-0 order-lg-1'}>
                <SwitchingBanner section={'gallery-show'} desktopBanner={"468x60"} className={"mb-3"} />

                <div className={'row'}>
                  <div className={'col-6 col-lg-4 offset-lg-2'}><VoteButton objectType={"gallery"} object={gallery} type={"like"} className={"btn-block"} /></div>
                  <div className={'col-6 col-lg-4'}><VoteButton objectType={"gallery"} object={gallery} type={"dislike"} className={"btn-block"} /></div>
                  <div className={'col-12 col-lg-8 offset-lg-2'}><ReactionBar object={gallery} objectType={'gallery'} /></div>
                  <div className={'col-12 col-lg-8 offset-lg-2'}><ShareBar object={gallery} /></div>
                </div>

                <div className={'row full-gutters'} key={"previews-" + gallery.slug}>
                  {gallery.images.map((image, index) => {
                    return (
                      <React.Fragment key={image.slug || image.id}>
                        <div className={'col-6 col-lg-3'}>
                          <Link to={`/gallery/${gallery.slug}/image/${index + 1}`}><HostedImage className={"full"} showLoader={true} image={image} size={"thumb"} /></Link>
                        </div>
                        {index === 11 && <div className={'col-12'}><SwitchingBanner desktopBanner={"468x60"} section={'gallery-show'} /></div>}
                      </React.Fragment>
                    )
                  })}
                </div>

                <NativeAds cacheKey={gallery.slug} type={'gallery-show'} />
              </div>
              <div className={'col-12 col-lg-2 order-2'}>
                <h3 className={'section-headline'}>Related Galleries</h3>
                {related && related.length > 0 && (
                  <div className={'row full-gutters'}>
                    {related.map((gallery) => {
                      return (
                        <div className={'col-6 col-lg-12'} key={gallery.slug}>
                          <Container gallery={gallery} />
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  let slug = ownProps.match.params.slug;

  if (state.all && state.all.galleries && state.all.galleries[slug]) {
    return { gallery: { loading: false, ...state.all.galleries[slug] } };
  }

  if (state.gallery && state.gallery.slug === slug) {
    return { gallery: { loading: false, ...state.gallery } };
  }

  return { gallery: { loading: true } };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchGallery }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GalleryShow));