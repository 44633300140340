import React, {Component} from 'react';
import { withRouter, Link } from "react-router-dom";

import FeatureArea from "../layout/FeatureArea";
import Sidebar from "../layout/Sidebar";
import List from "../image/List";

class ImageIndex extends Component {
  componentDidMount() {
    _sp.storage.set('image-tag', this.props.match.params.slug, "session");
    _sp.storage.set('image-sort', (this.props.sort || this.props.type), "session");
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    _sp.storage.set('image-tag', this.props.match.params.slug, "session");
    _sp.storage.set('image-sort', (this.props.sort || this.props.type), "session");
  }

  render () {
    let index, title, headline;

    switch (this.props.type) {
      case "tag":
        index = "tag-" + this.props.match.params.slug + "-" + (this.props.sort || "new");
        title = `View hot ${this.props.match.params.slug} images and gifs`;
        headline = `${(this.props.sort || "New")} ${this.props.match.params.slug} images and gifs`;
        break;
      case "top":
        index = "overview-top";
        title = "View hot cosplay porn images and gifs";
        headline = 'Hot cosplay Images and Gifs';
        break;
      default:
        index = "overview-new";
        title = "View new cosplay porn images";
        headline = 'New cosplay images and gifs';
        break;
    }

    return (
      <React.Fragment>
        <FeatureArea index={index} title={title} type={'images'} tag={this.props.match.params.slug} sort={this.props.type === "top" ? "top" : "popular"} />

        <div className={'container'}>
          <div className={'row'}>
            <div className={'col-12 col-lg-2'}>
              <div className={'sidebar-content-links'}>
                {(this.props.type === "tag" || this.props.type === "top") && <Link to={"/images"} className={"btn btn-block btn-default"}><i className={'fas fa-image'} /> All Images</Link>}

                {(this.props.type !== "tag" && this.props.type === "new") && <Link to={"/images/top"} className={"btn btn-block btn-default"}><i className={'fas fa-fire'} /> Hot Images</Link>}

                {(this.props.type === "tag" && this.props.sort !== "top") && <Link to={"/images/category/"+this.props.match.params.slug+"/top"} className={"btn btn-block btn-default"}><i className={'fas fa-fire'} /> Hot Images</Link>}
                {(this.props.type === "tag" && this.props.sort === "top") && <Link to={"/images/category/" + this.props.match.params.slug} className={"btn btn-block btn-default"}><i className={'fas fa-image'} /> New Images</Link>}
              </div>

              <Sidebar type={'images'} sort={(this.props.sort || this.props.type) === "top" ? "top" : "new"} />
            </div>
            <div className={'col-12 col-lg-10'}>
              <h2 className="section-headline">{headline}</h2>

              <List key={index} sort={(this.props.sort || this.props.type)} tag={this.props.match.params.slug} index={index} limit={24} paginated={true} />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(ImageIndex);