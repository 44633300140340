import React, {Component} from 'react';
import PropTypes from 'prop-types';

class ContentSlider extends Component {
  static defaultProps = {
    move: 1
  };

  constructor(props) {
    super(props);

    this.state = {
      offset: props.offset,
      prevVisible: false,
      nextVisible: this.props.count > this.props.visible
    };

    this.moveSlider = this.moveSlider.bind(this);
  }

  componentDidMount() {
    _sp.dom.select('.scroll-container').map((elem) => {
      elem.style.width = this.props.width + "%";
    });
  }

  moveToCenter(index) {
    if (index < 2) {
      index = 0;
    } else {
      index -= this.props.move;
    }

    this.changeIndex(index);
  }

  moveSlider(e) {
    switch (e.currentTarget.dataset.direction) {
      case "left":
        this.changeIndex(this.state.offset - this.props.move);
        break;
      case "right":
        this.changeIndex(this.state.offset + this.props.move);
        break;
    }
  }

  changeIndex(index) {
    if (index < 0) {
      index = 0;
    }

    let maxIndex = this.props.count - this.props.visible;
    if (index > maxIndex) {
      index = maxIndex;
    }

    let elem = this._slider.querySelector('.scroll-container');
    this.setState({ offset: index, prevVisible: index > 0, nextVisible: index + this.props.visible < this.props.count }, () => {
      if (this.props.count < this.props.visible) {
        return;
      }

      if (window.innerWidth >= 992) {
        elem.style.marginLeft = (this.state.offset * this.props.width * -1) + "%";
      } else {
        let wrapper = this._slider.querySelector('.wrapper');
        wrapper.scrollLeft = (this.state.offset * elem.getBoundingClientRect().width);
      }
    });
  }

  render() {
    return (
      <div className={'slider'} ref={elem => { this._slider = elem; }}>
        {this.state.prevVisible && <button onClick={this.moveSlider} className={'arrow arrow-left'} data-direction="left"><i className={'far fa-arrow-circle-left'} /></button>}
        <div className={'wrapper'}>
          <div className={'endless'}>
            {this.props.children}
          </div>
        </div>
        {this.state.nextVisible && <button onClick={this.moveSlider} className={'arrow arrow-right'} data-direction="right"><i className={'far fa-arrow-circle-right'} /></button>}
      </div>
    );
  }
}

ContentSlider.propTypes = {
  width: PropTypes.number,
  count: PropTypes.number,
  visible: PropTypes.number,
  selected: PropTypes.number,
  move: PropTypes.number
};

export default ContentSlider;