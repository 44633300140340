import React from 'react';
import { Provider } from 'react-redux'
import { Route, Switch } from "react-router-dom";
import { ConnectedRouter } from 'connected-react-router'

import Home from "./views/Home";
import Header from "../shared/Header";
import VideoShow from "./views/VideoShow";
import VideoIndex from "./views/VideoIndex";
import Footer from "../shared/Footer";
import ImageShow from "./views/ImageShow";
import ImageIndex from "./views/ImageIndex";
import PornstarShow from "./views/PornstarShow";
import PornstarIndex from "./views/PornstarIndex";
import GalleryShow from "./views/GalleryShow";
import GalleryIndex from "./views/GalleryIndex";
import SearchIndex from "./views/SearchIndex";
import TopicShow from "./views/TopicShow";
import Contact from "../shared/views/Contact";
import CamIndex from "./views/CamIndex";
import CamShow from "./views/CamShow";
import TagIndex from "./views/TagIndex";
import TagShow from "./views/TagShow";
import TermsShow from "../shared/views/TermsShow";
import GalleryImage from "./views/GalleryImage";
import SignIn from "../shared/user/SignIn";
import SignUp from "../shared/user/SignUp";
import ForgotPassword from "../shared/user/ForgotPassword";
import MetaTags from "../shared/MetaTags";
import BaseApp from "../BaseApp";

class App extends BaseApp {
  render() {
    return (
      <Provider store={this._store}>
        <ConnectedRouter history={this._history}>
          <React.Fragment>
            <MetaTags />
            <Header />

            <div className={'page-content'}>
              <Switch>
                <Route path="/search/page/:page" render={() => { return <SearchIndex /> }} />
                <Route path="/search" exact={true} render={() => { return <SearchIndex /> }} />

                <Route path="/topic/:slug/page/:page" render={() => { return <TopicShow /> }} />
                <Route path="/topic/:slug" render={() => { return <TopicShow /> }} />

                <Route path="/tag/:slug" render={() => { return <TagShow /> }} />
                <Route path="/tags" exact={true} render={() => { return <TagIndex /> }} />

                <Route path="/cam/:provider/:slug" render={() => { return <CamShow /> }} />
                <Route path="/cams/category/:slug" exact={true} render={() => { return <CamIndex type={'tag'} /> }} />
                <Route path="/cams" exact={true} render={() => { return <CamIndex type={'popular'} /> }} />

                <Route path="/pornstar/:slug" render={() => { return <PornstarShow /> }} />
                <Route path="/pornstars" exact={true} render={() => { return <PornstarIndex type={'alphabetical'} /> }} />

                <Route path="/gif/:slug" render={() => { return <ImageShow type={'gif'} /> }} />
                <Route path="/image/:slug" render={() => { return <ImageShow type={'image'} /> }} />
                <Route path="/images/category/:slug/top" render={() => { return <ImageIndex type={'tag'} sort={"top"} /> }} />
                <Route path="/images/category/:slug" render={() => { return <ImageIndex type={'tag'} /> }} />
                <Route path="/images/top" exact={true} render={() => { return <ImageIndex type={'top'} /> }} />
                <Route path="/images" exact={true} render={() => { return <ImageIndex type={'new'} /> }} />

                <Route path="/gallery/:slug/image/:index" render={() => { return <GalleryImage /> }} />
                <Route path="/gallery/:slug" render={() => { return <GalleryShow /> }} />
                <Route path="/galleries/category/:slug" render={() => { return <GalleryIndex type={'tag'} /> }} />
                <Route path="/galleries" exact={true} render={() => { return <GalleryIndex type={'new'} /> }} />

                <Route path="/video/:slug" render={() => { return <VideoShow /> }} />
                <Route path="/videos/category/:slug" render={() => { return <VideoIndex type={'tag'} /> }} />
                <Route path="/videos" exact={true} render={() => { return <VideoIndex type={'new'} /> }} />

                <Route path="/terms" exact={true} render={() => { return <TermsShow /> }} />
                <Route path="/report" exact={true} render={() => { return <Contact type={'report'} /> }} />
                <Route path="/contact" exact={true} render={() => { return <Contact type={'default'} /> }} />
                <Route path="/" exact={true} render={() => { return <Home /> }} />
              </Switch>
            </div>

            <SignIn/>
            <SignUp/>
            <ForgotPassword/>

            <Footer />
          </React.Fragment>
        </ConnectedRouter>
      </Provider>
    );
  }
}

export default App;