import React, {Component} from 'react';
import { withRouter } from "react-router-dom";

import FeatureArea from "../layout/FeatureArea";
import List from '../pornstar/List';

class PornstarIndex extends Component {
  render() {
    let index, title, headline;

    switch (this.props.type) {
      case "tag":
        index = "tag-" + this.props.match.params.slug;
        title = `Watch hot ${this.props.match.params.slug} pornstars`;
        headline = `New ${this.props.match.params.slug} pornstars`;
        break;
      default:
        index = "overview-alphabetical";
        title = "Watch pornstars doing cosplay porn";
        headline = "Pornstars";
        break;
    }

    return (
      <React.Fragment>
        <FeatureArea title={title} type={'pornstars'} tag={this.props.match.params.slug} />

        <div className={'container'}>
          <div className={'row'}>
            <div className={'col-12'}>
              <h2 className="section-headline">{headline}</h2>

              <List key={index} sort={this.props.type} tag={this.props.match.params.slug} index={index} limit={24} paginated={true} />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(PornstarIndex);