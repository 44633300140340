export const fetchCams = (opts={}) => (dispatch) => {
  let sort = opts.sort || "new";
  let index = opts.index || "all";
  let limit = opts.limit || 20;
  let page = opts.page || 1;
  let tag = opts.tag;

  return _sp.axios.get(settings.api_base + "/" + settings.api_version + '/cam', {
    params: {
      tags: tag,
      sort: sort,
      limit: limit,
      page: page
    }
  })
    .then(res => {
      return res.data;
    })
    .then(data => {
      dispatch({
        type: "CAMS_LOADED",
        data: data,
        index: index
      });
    })
};

export const camsReducer = (state = {}, action) => {
  switch (action.type) {
    case "CAMS_LOADED":
      if (!action.data.objects) {
        action.data.objects = [];
      }

      let existing = state[action.index];

      let objects = (existing ? existing.objects : []).concat(action.data.objects);

      return Object.assign({}, state, {
        [`${action.index}`]: {
          loading: false,
          objects: objects,
          meta_tags: action.data.meta_tags,
          pagination: action.data.pagination,
          util: action.data.util
        }
      });
    default:
      return state;
  }
};

export const fetchCam = (id, opts={}) => (dispatch) => {
  return _sp.axios.get(settings.api_base + "/" + settings.api_version + '/cam/' + id, {
    params: opts
  })
  .then(res => {
    return res.data;
  })
  .then(data => {
    dispatch({
      type: "CAM_LOADED",
      data: data
    });
  })
};

export const camReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case "CAM_LOADED":
      return action.data;
    default:
      return state;
  }
};