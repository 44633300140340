import React, {Component} from 'react';
import Overlay from "../../porn_app/utility/Overlay";
import SocialLogin from "./SocialLogin";

class SignUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      errors: {}
    };

    this.onShow = this.onShow.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onSignUp = this.onSignUp.bind(this);
    this.onSignInClick = this.onSignInClick.bind(this);
  }

  componentDidMount() {
    window.addEventListener('showSignUp', this.onShow)
  }

  componentWillUnmount() {
    window.removeEventListener('showSignUp', this.onShow)
  }

  onShow() {
    this.setState({ visible: true });
  }

  onClose() {
    this.setState({ visible: false });
  }

  onSignUp(e) {
    e.preventDefault();

    let user = this._form.querySelector('[name="username"]').value;
    let email = this._form.querySelector('[name="email"]').value;
    let password = this._form.querySelector('[name="password"]').value;

    this.setState({ errors: {} }, () => {
      _sp.user.signUp(user, email, password)
        .then(() => {
          this.setState({ visible: false });
        })
        .catch(error => {
          this.setState({ errors: error.response.data.errors });
        });
    });
  }

  onSignInClick() {
    this.setState({ visible: false });
    window.dispatchEvent(new Event('showSignIn'));
  }

  render() {
    if (!this.state.visible) {
      return "";
    }

    return (
      <Overlay clickCallback={this.onClose}>
        <div className={'content-overlay-body sign-up'}>
          <button className={'close-button'} onClick={this.onClose} alt={"Login"}><i className={'far fa-times'} /></button>
          <h2 className={'page-headline'}>Create an Account</h2>

          <button className={'btn btn-danger'} alt={"Sign in"} onClick={this.onSignInClick}>Already have an Account? Sign in here</button>

          <h3 className={'content-headline'}>Register with a social network</h3>

          <div className={'row no-gutters social-logins'}>
            <div className={'col-6 col-md-3'}><SocialLogin provider={"google"} /></div>
            <div className={'col-6 col-md-3'}><SocialLogin provider={"facebook"} /></div>
            <div className={'col-6 col-md-3'}><SocialLogin provider={"twitter"} /></div>
            <div className={'col-6 col-md-3'}><SocialLogin provider={"discord"} /></div>
          </div>

          <h3 className={'content-headline'}>Or register with your email</h3>

          <div className={'mb-4'}>
            Register for this site is easy. Just fill in the fields below, and we’ll get a new account set up for you in no time.
          </div>

          <form onSubmit={this.onSignUp} ref={(e) => { this._form = e; }}>
            <div className={'row'}>
              <div className={'col-12 col-md-6'}>
                {this.state.errors.username && <div className={'errors'}>{this.state.errors.username.join(", ")}</div>}
                <label htmlFor="username">Username</label>
                <input type={'text'} name={'username'} className={'form-control'} />
              </div>
              <div className={'col-12 col-md-6'}>
                {this.state.errors.email && <div className={'errors'}>{this.state.errors.email.join(", ")}</div>}
                <label htmlFor="email">E-Mail-Address</label>
                <input type={'text'} name={'email'} className={'form-control'} />
              </div>
              <div className={'col-12 col-md-6 mt-3'}>
                {this.state.errors.password && <div className={'errors'}>{this.state.errors.password.join(", ")}</div>}
                <label htmlFor="password">Password</label>
                <input type={'password'} name={'password'} className={'form-control'} />
              </div>
            </div>

            <div className={'text-center mt-3 mb-3'}>
              By clicking Sign Up you’ll accept our <a href={"/terms"} target={"_blank"}>Terms & Conditions</a>
            </div>

            <div className={'row'}>
              <div className={'col-12 col-lg-4 offset-lg-4'}>
                <button className={'btn btn-danger btn-block'} alt={"Sign up"}>Sign Up</button>
              </div>
            </div>
          </form>
        </div>
      </Overlay>
    );
  }
}

export default SignUp;