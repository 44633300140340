import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import {videosReducer, videoReducer} from "./videos";
import {imagesReducer, imageReducer} from "./images";
import { allReducer } from './all';
import { sidebarReducer } from './sidebar';
import { featuredReducer } from "./featured";
import { pornstarsReducer, pornstarReducer } from "./pornstars";
import { topicsReducer, topicReducer } from "./topics";
import {galleriesReducer, galleryReducer} from "./gallery";
import {searchReducer} from "./search";
import {camReducer, camsReducer} from "./cams";
import {tagReducer, tagsReducer} from "./tags";

const createRootReducer = (history) => combineReducers({
  all: allReducer,
  cam: camReducer,
  cams: camsReducer,
  config: (state = {}, action) => { return state },
  featured: featuredReducer,
  gallery: galleryReducer,
  galleries: galleriesReducer,
  images: imagesReducer,
  image: imageReducer,
  navigation: (state = {}, action) => { return state },
  pornstars: pornstarsReducer,
  pornstar: pornstarReducer,
  search: searchReducer,
  sidebar: sidebarReducer,
  tags: tagsReducer,
  tag: tagReducer,
  topic: topicReducer,
  topics: topicsReducer,
  videos: videosReducer,
  video: videoReducer,
  router: connectRouter(history)
});

export default createRootReducer;