import React from "react"
import PropTypes from 'prop-types';

class Loader extends React.Component {
  static defaultProps = {
    inline: false
  };

  render () {
    let loader = (
      <div className='hollow-dots-spinner'>
        <div className='dot' />
        <div className='dot' />
        <div className='dot' />
      </div>
    );

    if (this.props.inline) {
      return (
        <div className={'inline-loader'}>
          {loader}
        </div>
      );
    }

    return (
      <div className={'loader'}>
        {loader}
      </div>
    );
  }
}

Loader.propTypes = {
  inline: PropTypes.bool
};

export default Loader
