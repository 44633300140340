export const fetchSearch = (opts={}) => (dispatch) => {
  return _sp.axios.get(settings.api_base + "/" + settings.api_version + '/search', {
    params: opts
  })
  .then(res => {
    return res.data;
  })
  .then(data => {
    dispatch({
      type: "SEARCH_LOADED",
      data: data
    });
  })
};

export const searchReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case 'SEARCH_LOADED':
      if (!action.data.objects) {
        action.data.objects = [];
      }

      return action.data;
    default:
      return state;
  }
};