export const fetchFeatured = (opts={}) => (dispatch) => {
  let sort = opts.sort || "new";
  let index = opts.index || "all";
  let limit = opts.limit || 20;
  let page = opts.page || 1;
  let tag = opts.tag;
  let format = opts.format;
  let type = opts.type;
  let filters = opts.filters || {};

  return _sp.axios.get(settings.api_base + "/" + settings.api_version + '/featured', {
    params: {
      tags: tag,
      sort: sort,
      limit: limit,
      image_format: format,
      page: page,
      type: type,
      ...filters
    }
  })
    .then(res => {
      return res.data;
    })
    .then(data => {
      dispatch({
        type: "FEATURED_LOADED",
        data: data,
        index: index,
        indexType: type
      });
    })
};

export const featuredReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case "FEATURED_LOADED":
      if (!action.data.objects) {
        action.data.objects = [];
      }

      let objects = action.data.objects;

      return Object.assign({}, state, {
        [`${action.index}-${action.indexType}`]: {
          loading: false,
          objects: objects,
          meta_tags: action.data.meta_tags,
          pagination: action.data.pagination,
          util: action.data.util
        }
      });
    default:
      return state;
  }
};