import React, {Component} from 'react';

import VideoPlayer from "../utility/VideoPlayer";
import Loader from "../../shared/Loader";

class Content extends Component {
  generateFrame() {
    const { video } = this.props;

    if (video.provider === "gfycat") {
      return (
        <div className={'video-player'}>
          <VideoPlayer autoplay={true} controls={true} sources={[{ src: video.embed, type: "video/mp4" }]} advert={this.props.advert} />
        </div>
      );
    }

    switch (video.provider) {
      case "pornhub":
      case "tube8":
        return <div className={'embed-container'}><iframe src={video.embed} title={video.title} allowFullScreen={true} sandbox={"allow-scripts allow-same-origin"} /></div>;
    }

    return <div className={'embed-container'}><iframe src={video.embed} title={video.title} allowFullScreen={true} /></div>;
  }

  render() {
    const { video } = this.props;

    if (video.video_type !== "hosted") {
      return this.generateFrame();
    }

    if (!video.file) {
      return <Loader inline={true} />
    }

    return (
      <div className={'video-player'}>
        <VideoPlayer autoplay={true} controls={true} sources={[{ src: video.file, type: "video/mp4" }]} advert={this.props.advert} />
      </div>
    );
  }
}

export default Content;