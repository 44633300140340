import React, {Component} from 'react';
import PropTypes from "prop-types"
import { Link } from 'react-router-dom';

import HostedImage from "../../shared/HostedImage";

class Container extends Component {
  static defaultProps = {
    mixed: false,
    title: true
  };

  render() {
    const { cam } = this.props;

    return (
      <article className={'object-container'}>
        <Link to={`${this.props.fullUrl || '/cam/' + cam.provider + "/" + cam.username}`} className={'image-container'} title={cam.title}>
          <HostedImage image={cam.preview} mixed={false} className={'full'} alt={cam.title} mixed={this.props.mixed} />
          {this.props.mixed && <i className={'fas fa-video icon'} />}
        </Link>
        {(!this.props.mixed || this.props.title) && (
          <div className={'info-row'}>
            <div className={'utility'}>
              <i className={'far fa-users'} /> {cam.viewers.toLocaleString()}
            </div>

            <h3><Link to={`/cam/${cam.slug}`} title={cam.title}>{cam.title}</Link></h3>
          </div>
        )}
      </article>
    );
  }
}

Container.propTypes = {
  cam: PropTypes.object,
  mixed: PropTypes.bool
};

export default Container;