import React, {Component} from 'react';
import Overlay from "../../porn_app/utility/Overlay";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      resetted: false,
      errors: {}
    };

    this.onShow = this.onShow.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    window.addEventListener('showLostPassword', this.onShow)
  }

  componentWillUnmount() {
    window.removeEventListener('showLostPassword', this.onShow)
  }

  onShow() {
    this.setState({ visible: true });
  }

  onClose() {
    this.setState({ visible: false });
  }

  onSubmit(e) {
    e.preventDefault();

    let email = this._form.querySelector('[name="email"]').value;

    this.setState({ error: false }, () => {
      _sp.user.requestPassword(email)
        .then((res) => {
          this.setState({ resetted: true });
        })
        .catch(error => {
          this.setState({ errors: error.response.data.errors });
        });
    });
  }

  render() {
    if (!this.state.visible) {
      return "";
    }

    return (
      <Overlay clickCallback={this.onClose}>
        <div className={'content-overlay-body lost-password text-center'}>
          <button className={'close-button'} alt={"Close"} onClick={this.onClose}><i className={'far fa-times'} /></button>
          <h2 className={'page-headline'}>Forgot your password?</h2>

          {this.state.resetted ? (
            <div>
              We have sent you instructions to reset your Password.
              <br /><br />
              <button className={'btn btn-danger'} alt={"Close"} onClick={this.onClose}>Close</button>
            </div>
          ) : (
            <form onSubmit={this.onSubmit} ref={(e) => { this._form = e; }}>
              <div className={'row'}>
                <div className={'col-12 col-xl-6 offset-xl-3'}>
                  <label htmlFor="email">E-Mail-Address</label>
                  {this.state.errors.email && <div className={'errors'}>{this.state.errors.email.join(", ")}</div>}
                  <input type={'text'} name={'email'} className={'form-control'} />
                </div>
              </div>

              <div className={'row mt-3'}>
                <div className={'col-12 col-lg-6 offset-lg-3'}>
                  <button className={'btn btn-danger btn-block'} alt={"Send new Password"}>Send new Password</button>
                </div>
              </div>
            </form>
          )}
        </div>
      </Overlay>
    );
  }
}

export default ForgotPassword;