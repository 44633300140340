import React, {Component} from 'react';
import PropTypes from 'prop-types';
import TagsInput from 'react-tagsinput'

import Overlay from "../utility/Overlay";

class TagSuggest extends Component {
  static defaultState = {
    tags: [],
    saving: false,
    error: false,
    visible: false
  };

  constructor(props) {
    super(props);
    this.state = TagSuggest.defaultState;

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onToggle = this.onToggle.bind(this);
  }

  onToggle() {
    this.setState({ visible: !this.state.visible });
  }

  onChange(tags) {
    this.setState({ tags: tags });
  }

  onSubmit(e) {
    e.preventDefault();

    this.setState({ saving: true });

    _sp.axios.post(settings.api_base + "/" + settings.api_version + '/tag/suggest?type=' + this.props.type + "&slug=" + this.props.slug + "&tags=" + this.state.tags.join(",")).then(() => {
      this.setState(TagSuggest.defaultState);
    }).catch(() => {
      this.setState({ saving: false, error: true });
    });
  }

  render() {
    return (
      <React.Fragment>
        <button onClick={this.onToggle} className={'link'}><i className={'far fa-plus'} /> add tags</button>

        {this.state.visible && (
          <Overlay clickCallback={this.onToggle}>
            <div className={'content-overlay-body sign-in'}>
              <button className={'close-button'} onClick={this.onToggle}><i className={'far fa-times'} /></button>
              <h2 className={'page-headline text-center'}>Suggest Tags</h2>

              {this.state.saving ? (
                <div className={'inline-loading'}><i className={'fas fa-spin fa-spinner'} /> Saving...</div>
              ) : (
                <form onSubmit={this.onSubmit}>
                  {this.state.error && <div className={'errors'}>There was an error saving your tags, sorry :(</div>}

                  <TagsInput value={this.state.tags} onChange={this.onChange} onlyUnique={true} addOnBlur={true} />
                  <button className={'btn btn-default mt-3'}>Save Tags</button>
                </form>
              )}
            </div>
          </Overlay>
        )}
      </React.Fragment>
    );
  }
}

TagSuggest.defaultProps = {
  type: PropTypes.string,
  slug: PropTypes.string
};

export default TagSuggest;