import React, {Component} from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom'

class Pagination extends Component {
  generateParamString() {
    const { params } = this.props;
    if (!params) {
      return "";
    }

    return "?" + Object.keys(params).map(function(key) {
      if (!params[key]) {
        return null;
      }

      return key + '=' + encodeURIComponent(params[key]);
    }).filter(p => p).join('&');
  }

  render() {
    const { pages, baseUrl } = this.props;

    if (pages.total_pages <= 1) {
      return "";
    }

    let paramString = this.generateParamString();

    let start = pages.current_page - 3;
    if (start < 1) {
      start = 1;
    }
    let end = pages.current_page + 3;
    if (end > pages.total_pages) {
      end = pages.total_pages;
    }

    let gutters = [];
    for (let i = start; i <= end; i++) {
      gutters.push(<Link className={'small scroll-top ' + (i === pages.current_page ? 'active' : '')} key={'page-' + i} to={baseUrl + (i > 1 ? "/page/" + i : '') + paramString}>{i}</Link>);
    }

    return (
      <div className={'pagination text-center'}>
        {pages.current_page > 1 && <Link className={'large scroll-top'} to={baseUrl + paramString}><i className={'fas fa-angle-double-left'} /></Link>}
        {pages.prev_page && <Link className={'small scroll-top'} to={baseUrl + "/page/" + pages.prev_page + paramString}><i className={'fas fa-angle-left'} /></Link>}
        {gutters}
        {pages.next_page && <Link className={'small scroll-top'} to={baseUrl + "/page/" + pages.next_page + paramString}><i className={'fas fa-angle-right'} /></Link>}
        {pages.current_page < pages.total_pages && <Link className={'large scroll-top'} to={baseUrl + "/page/" + pages.total_pages + paramString}><i className={'fas fa-angle-double-right'} /></Link>}
      </div>
    );
  }
}

Pagination.propTypes = {
  pages: PropTypes.object,
  baseUrl: PropTypes.string
};

export default Pagination;