import React from "react"
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link, withRouter } from "react-router-dom";

// import { expired } from "../../../redux/helpers";
import { fetchVideos } from "../../../redux/videos";
import Container from "./Container";
import VisibleEvent from "../../shared/VisibleEvent";
import Loader from "../../shared/Loader";
import BaseList from "../utility/BaseList";
import SwitchingBanner from "../../shared/ads/SwitchingBanner";

class List extends BaseList {
  loadObjects(opts={}) {
    this.props.fetchVideos({
      sort: this.props.sort,
      index: this.props.index,
      tag: this.props.tag,
      page: this.state.page,
      limit: this.props.limit,
      filters: { duration: _sp.dom.getParameterByName("duration"), provider: _sp.dom.getParameterByName("provider"), ...this.props.filter },
      reducer: { ...opts }
    }).then(() => {
      this.setState({ loading: false });
    });
  }

  render () {
    const { data } = this.props;

    if (data.loading) {
      return <Loader inline={true} />;
    }

    return (
      <React.Fragment>
        <div className={'row full-gutters'}>
          {!data.objects || data.pagination.total_count <= 0 ? (
            <div>No Videos found :(</div>
          ) : (
            data.objects.map((video, index) => {
              return (
                <React.Fragment key={video.slug}>
                  <div className={this.columnClasses()}>
                    <Container video={video} />
                  </div>
                  {this.props.ads !== false && index > 0 && (index + 1) % 6 === 0 && <div className={'col-12'}><SwitchingBanner section={'videos'} /></div>}
                </React.Fragment>
              )
            })
          )}
        </div>
        {data.pagination.next_page && (
          <div className={'text-center mt-3'}>
            {this.props.paginated ? (
              <VisibleEvent key={"page-" + data.pagination.current_page} inViewport={this.visitNextPage} offset={1000}>
                <button ref={(e) => { this._nextPageButton = e; }} onClick={this.visitNextPage} className={'btn btn-default'}>Next Page</button>
              </VisibleEvent>
            ) : (
              <Link to={"/videos"} className={'btn btn-default scroll-top'}>View more</Link>
            )}
          </div>
        )}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state, ownProps) {
  if (!state.videos[ownProps.index]) {
    return { data: { loading: true } };
  }

  /*if (expired(state.videos[ownProps.index].util)) {
    return { data: { loading: true } };
  }*/

  return { data: state.videos[ownProps.index] };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchVideos }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(List));