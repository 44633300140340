import React from 'react';
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import { fetchFeatured } from "../../../redux/featured";
import Loader from "../../shared/Loader";
import MixedContainer from "../utility/MixedContainer";
import BaseList from "../utility/BaseList";

class MixedList extends BaseList {
  loadObjects(opts={}) {
    this.props.fetchFeatured({
      sort: this.props.sort,
      index: this.props.index,
      tag: this.props.tag,
      page: this.state.page,
      limit: this.props.limit,
      filters: { duration: _sp.dom.getParameterByName("duration"), ...this.props.filter },
      type: "all"
    }).then(() => {
      this.setState({ loading: false });
    });
  }

  render () {
    const { data } = this.props;

    if (data.loading) {
      return <Loader inline={true} />;
    }

    return (
      <React.Fragment>
        <div className={'row full-gutters'}>
          {!data.objects || data.pagination.total_count <= 0 ? (
            <div>No Videos found :(</div>
          ) : (
            data.objects.map((obj) => {
              return (
                <div key={(obj.id || obj.slug)} className={this.columnClasses()}>
                  <MixedContainer object={obj} />
                </div>
              )
            })
          )}
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state, ownProps) {
  let index = (ownProps.index || "all") + "-all";

  if (state.featured && state.featured[index]) {
    return { data: state.featured[index] };
  }

  return { data: { loading: true } };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchFeatured }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MixedList));