import React, {Component} from 'react';
import PropTypes from 'prop-types';

class Banner extends Component {
  constructor(props) {
    super(props);

    this.state = {
      timeStamp: Math.round(new Date().getTime() + Math.random(999999))
    }
  }

  componentDidMount() {
    this.addExoloader();
  }


  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.cacheKey !== this.props.cacheKey || (prevProps.device !== this.props.device)) {
      this.setState({ timeStamp: (Math.round(new Date().getTime() + Math.random(999999))) }, () => {
        this.addExoloader();
      })
    }
  }

  addExoloader() {
    let size= this.props.size;
    if (size === "300x100") {
      size = "300x250";
    }

    let adblockIds = {
      "728x90": "3451953",
      "300x250": "3451951",
      "468x60": "3451955"
    };

    let adblockId = adblockIds[size];
    if (!adblockId) {
      return;
    }

    let sizes = size.split("x");
    let width = sizes[0], height = sizes[1];

    let elem = document.getElementById("i" + this.state.timeStamp);
    if (elem) {
      let data = {'type': 'banner', 'width': width, 'height': height, 'idzone': adblockId, 'sub': '1', codeId: "i" + this.state.timeStamp};
      _sp.addExoZone(data);
    }

    return true;
  }

  render() {
    // TODO type-check

    let mapping = {
      "728x90": {
        width: 728,
        height: 90,
        zones: {
          default: { zoneId: 63 },
          videos: { zoneId: 60 },
          "video-show": { zoneId: 61 },
          galleries: { zoneId: 59 },
          "gallery-show": { zoneId: 62 },
          images: { zoneId: 38 },
          "image-show": { zoneId: 58 }
        }
      },
      "300x100": {
        width: 300,
        height: 100,
        zones: {
          default: { zoneId: 45 },
          videos: { zoneId: 40 },
          "video-show": { zoneId: 41 },
          galleries: { zoneId: 43 },
          "gallery-show": { zoneId: 40 },
          images: { zoneId: 37 },
          "image-show": { zoneId: 42 }
        }
      },
      "468x60": {
        width: 468,
        height: 60,
        zones: {
          default: { zoneId: 51 },
          videos: { zoneId: 49 },
          "video-show": { zoneId: 50 },
          galleries: { zoneId: 47 },
          "gallery-show": { zoneId: 48 },
          images: { zoneId: 37 },
          "image-show": { zoneId: 46 }
        }
      },
      "300x250": {
        width: 300,
        height: 250,
        zones: {
          default: { zoneId: 57 },
          videos: { zoneId: 55 },
          "video-show": { zoneId: 56 },
          galleries: { zoneId: 36 },
          "gallery-show": { zoneId: 52 },
          images: { zoneId: 53 },
          "image-show": { zoneId: 54 }
        }
      },
    };

    let zone = mapping[this.props.size];

    let zoneId = zone.zones.default.zoneId;
    if (zone.zones[this.props.section]) {
      zoneId = zone.zones[this.props.section].zoneId;
    }

    let args = [
      'zoneid=' + zoneId,
      'cb=' + this.state.timeStamp
    ];

    if (this.props.cacheKey) {
      args.push('cacheKey=' + this.props.cacheKey);
    }

    if (this.props.device) {
      args.push("device=" + this.props.device);
    }

    if (this.props.type) {
      args.push("type=" + this.props.type);
    }

    let url = '//ads.theporndb.com/www/delivery/afr.php?' + args.join("&");
    let classes = ['text-center'];
    if (this.props.className) {
      classes.push(this.props.className);
    }

    return (
      <div className={classes.join(" ")} data-section={this.props.section}>
        <iframe src={url} frameBorder='0' scrolling='no' width={zone.width} height={zone.height} />
        <div id={'i' + this.state.timeStamp} />
      </div>
    );
  }
}

Banner.propTypes = {
  size: PropTypes.string,
  section: PropTypes.string,
  type: PropTypes.string
};

export default Banner;