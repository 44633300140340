import React, {Component} from 'react';
import { withRouter } from "react-router-dom";

import List from '../tag/List';

class TagIndex extends Component {
  render() {
    return (
      <div className={'container'}>
        <div className={'row'}>
          <div className={'col-12'}>
            <h2 className="section-headline">Popular Tags</h2>

            <List index={"overview"} sort={"hot"} rowCount={4} limit={40} paginated={true} />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(TagIndex);