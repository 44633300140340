/* eslint-disable */

Array.prototype.toSentence = function(connector = 'and') {
  if (this.length <= 1) {
    return this;
  }

  if (this.length > 2) {
    let length = (this.length * 2) - 3;
    for (let i = 1; i < length; i += 2) {
      this.splice(i, 0, ", ");
    }
  }

  this.splice(this.length - 1, 0, " " + connector + " ");

  return this;
};

Array.prototype.remove = function(value) {
  let index = this.indexOf(value);
  return this.splice(index, 1);
};