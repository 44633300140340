import React, {Component} from 'react';

class ShareBar extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.setState({ siteTitle: settings.site_title, objUrl: window.location.href });
  }

  render() {
    const { object } = this.props;

    let objUrl = encodeURI(this.state.objUrl);
    let title = encodeURI(object.title || "Untitled");
    let hashTags = (object.tags || []).map(t => t.slug).join(",");

    let twitterUrl = "https://twitter.com/intent/tweet?original_referer=" + objUrl + "&text=" + title + "&url=" + objUrl + "&hashtags=" + hashTags;
    let redditUrl = "//www.reddit.com/submit?url="+objUrl+"&title=" + title;
    let tumblrUrl = "https://www.tumblr.com/share/link?url="+objUrl+"&name=" + title;

    return (
      <div className={'social-share-bar'}>
        <a className={'twitter'} href={twitterUrl} target={"_blank"}><i className={'fab fa-twitter'} /></a>
        <a className={'reddit'} href={redditUrl} target={"_blank"}><i className={'fab fa-reddit'} /></a>
        <a className={'tumblr'} href={tumblrUrl} target={"_blank"}><i className={'fab fa-tumblr'} /></a>
      </div>
    );
  }
}

export default ShareBar;