import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

import {fetchPornstars} from "../../../redux/pornstars";
import Loader from "../../shared/Loader";
import HostedImage from "../../shared/HostedImage";
import ContentSlider from "../utility/ContentSlider";

class Slider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      offset: 0,
      prevVisible: false,
      nextVisible: true,
      sliderProps: {
        loading: true
      }
    };
  }

  componentDidMount() {
    if (this.props.data.loading) {
      this.props.fetchPornstars({
        limit: 16,
        preview_format: "vertical"
      });
    }

    this.generateSlider();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.generateSlider();
  }

  generateSlider() {
    if (this.props.data.loading || !this.state.sliderProps.loading) {
      return;
    }

    let count = 4;
    if (window.innerWidth < 1024) {
      count = 2;
    }

    this.setState({
      sliderProps: {
        loading: false,
        width: 100 / count,
        visible: count,
        count: this.props.data.objects.filter(ps => ps.preview).length,
        move: 1
      }
    })
  }

  render() {
    const { data } = this.props;

    if (data.loading || this.state.sliderProps.loading) {
      return <Loader inline={true} />
    }

    return (
      <ContentSlider offset={0} {...this.state.sliderProps}>
        {data.objects.filter(ps => ps.preview).map((pornstar) => {
          return (
            <Link className={'scroll-container'} key={pornstar.slug} to={"/pornstar/" + pornstar.slug}>
              <HostedImage image={pornstar.preview} size={"thumb"} alt={pornstar.name} />
              <h3>{pornstar.name}</h3>
            </Link>
          )
        })}
      </ContentSlider>
    );
  }
}

function mapStateToProps(state, ownProps) {
  if (state.pornstars && state.pornstars["all"]) {
    return { data: state.pornstars["all"] }
  }

  return { data: { loading: true } };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchPornstars }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Slider);