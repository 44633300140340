import React, {Component} from 'react';

class WithoutLogin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loggedIn: false
    };

    this.onUserStateChange = this.onUserStateChange.bind(this);
  }

  componentDidMount() {
    window.addEventListener('userStateChanged', this.onUserStateChange);
    this.setState({ loggedIn: _sp.user.isLoggedIn() });
  }

  componentWillUnmount() {
    window.removeEventListener('userStateChanged', this.onUserStateChange)
  }

  onUserStateChange() {
    this.setState({ loggedIn: _sp.user.isLoggedIn() });
  }

  render() {
    if (this.state.loggedIn) {
      return "";
    }

    return this.props.children;
  }
}

export default WithoutLogin;