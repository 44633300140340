export const fetchSidebar = (opts={}) => (dispatch) => {
  let type = opts.type;
  let sort = opts.sort || "new";
  let aggs = opts.aggs || {};

  return _sp.axios.get(settings.api_base + "/" + settings.api_version + '/sidebar', {
    params: {
      type: type,
      sort: sort,
      ...aggs
    }
  })
  .then(res => {
    return res.data;
  })
  .then(data => {
    dispatch({
      type: "SIDEBAR_LOADED",
      data: data,
      index: type + "-" + sort
    });
  })
};

export const sidebarReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case 'SIDEBAR_LOADED':
      return Object.assign({}, state, {
        [`${action.index}`]: action.data
      });
    /*case 'VIDEOS_LOADED':
      let categories = [];

      action.data.objects.forEach((object) => {
        object.tags.forEach((tag) => {
          if (categories.filter(c => c.slug === tag.slug).length === 0) {
            categories.push({ name: tag.name, slug: tag.slug });
          }
        })
      });

      return { categories: categories.sort((a,b) => { return a.name > b.name ? 1 : -1; }) };*/
    default:
      return state;
  }
};