export const fetchTags = (opts={}) => (dispatch) => {
  let sort = opts.sort || "new";
  let limit = opts.limit || 20;
  let page = opts.page || 1;
  let index = opts.index;

  return _sp.axios.get(settings.api_base + "/" + settings.api_version + '/tag', {
    params: {
      sort: sort,
      limit: limit,
      page: page
    }
  })
    .then(res => {
      return res.data;
    })
    .then(data => {
      dispatch({
        type: "TAGS_LOADED",
        data: data,
        index: index + "-" + sort
      });
    })
};

export const tagsReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case "TAGS_LOADED":
      if (!action.data.objects) {
        action.data.objects = [];
      }

      let existing = state[action.index];
      let objects = (existing ? existing.objects : []).concat(action.data.objects);

      return Object.assign({}, state, {
        [action.index]: {
          loading: false,
          objects: objects,
          meta_tags: action.data.meta_tags,
          pagination: action.data.pagination,
          util: action.data.util
        }
      });
    default:
      return state;
  }
};

export const fetchTag = (id, opts={}) => (dispatch) => {
  return _sp.axios.get(settings.api_base + "/" + settings.api_version + '/tag/' + id, {
    params: opts
  })
    .then(res => {
      return res.data;
    })
    .then(data => {
      dispatch({
        type: "TAG_LOADED",
        data: data,
        index: id
      });
    })
};

export const tagReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case "TAG_LOADED":
      if (!action.data.objects) {
        action.data.objects = [];
      }

      let existing = state[action.index];
      let objects = (existing ? existing.objects : []).concat(action.data.objects);

      return Object.assign({}, state, {
        [action.index]: {
          loading: false,
          name: action.data.name,
          objects: objects,
          meta_tags: action.data.meta_tags,
          pagination: action.data.pagination,
          util: action.data.util
        }
      });
    default:
      return state;
  }
};