import React, {Component} from 'react';
import { withRouter } from "react-router-dom";

import Sidebar from "../layout/Sidebar";
import FeatureArea from "../layout/FeatureArea";
import List from "../cam/List";

class CamIndex extends Component {
  render() {
    let index, title, headline;

    switch (this.props.type) {
      case "tag":
        index = "tag-" + this.props.match.params.slug;
        title = `Watch hot ${this.props.match.params.slug} cams`;
        headline = `New ${this.props.match.params.slug} Cams`;
        break;
      default:
        index = "overview-new";
        title = "Watch hot cosplay porn cams";
        headline = "New Cams";
        break;
    }

    return (
      <React.Fragment>
        <FeatureArea title={title} type={'cams'} tag={this.props.match.params.slug} />

        <div className={'container'}>
          <div className={'row'}>
            <div className={'col-12 col-lg-2'}>
              <Sidebar type={"cams"} />
            </div>
            <div className={'col-12 col-lg-10'}>
              <h2 className="section-headline">{headline}</h2>

              <List key={index} sort={this.props.type} tag={this.props.match.params.slug} index={index} limit={24} paginated={true} />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(CamIndex);