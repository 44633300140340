import React, {Component} from 'react';

class ReactionCounts extends Component {
  reactionCount(reaction) {
    const { object } = this.props;

    let count = object.reactions[reaction] || 0;
    if (count > 99) {
      return "99+";
    }

    return count;
  }

  render() {
    const { object } = this.props;
    if (!object.reactions) {
      return "";
    }

    let keys = Object.keys(object.reactions).slice(0, 4);

    return (
      <div className={'reaction-counts'}>
        {keys.map((reaction) => {
          if (this.reactionCount(reaction) <= 0) {
            return "";
          }

          return (
            <div className={'reaction-container'} key={reaction}>
              <img className={'reaction-' + reaction} alt="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABAAQMAAACQp+OdAAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAAA9JREFUeNpjYBgFo4B8AAACQAAB4E0dRAAAAABJRU5ErkJggg==" />
              <div className="reaction-count">{this.reactionCount(reaction)}</div>
            </div>
          )
        })}
      </div>
    );
  }
}

export default ReactionCounts;