import React, {Component} from 'react';
import PropTypes from "prop-types"

class SocialLogin extends Component {
  constructor(props) {
    super(props);

    this.onLoginClick = this.onLoginClick.bind(this);
  }

  onLoginClick() {
    window.location.href = "/user/oauth?provider=" + this.props.provider + "&redirect=" + encodeURI(window.location.pathname);
  }

  render() {
    return (
      <button onClick={this.onLoginClick} className={'social-login ' + this.props.provider}><i className={'fab fa-' + this.props.provider} /></button>
    );
  }
}

SocialLogin.defaultProps = {
  provider: PropTypes.string
};

export default SocialLogin;