import React from 'react';
import {Link, withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import { fetchTag } from "../../../redux/tags";
import Loader from "../../shared/Loader";
import MixedContainer from "../utility/MixedContainer";
import BaseList from "../utility/BaseList";
import VisibleEvent from "../../shared/VisibleEvent";

class TagOverviewList extends BaseList {
  loadObjects(opts={}) {
    this.props.fetchTag(this.props.slug, {
      sort: this.props.sort,
      limit: this.props.limit,
      page: this.state.page,
    }).then(() => {
      this.setState({ loading: false });
    });
  }

  render () {
    const { data } = this.props;

    if (data.loading) {
      return <Loader inline={true} />;
    }

    return (
      <React.Fragment>
        <h2 className="section-headline">View everything tagged with {data.name}</h2>

        <div className={'row full-gutters'}>
          {data.pagination.total_count <= 0 ? (
            <div>No Objects found :(</div>
          ) : (
            data.objects.map((obj) => {
              return (
                <div key={(obj.id || obj.slug)} className={this.columnClasses()}>
                  <MixedContainer object={obj} />
                </div>
              )
            })
          )}
        </div>
        {data.pagination.next_page && (
          <div className={'text-center mt-3'}>
            {this.props.paginated ? (
              <VisibleEvent key={"page-" + data.pagination.current_page} inViewport={this.visitNextPage} offset={1000}>
                <button ref={(e) => { this._nextPageButton = e; }} onClick={this.visitNextPage} className={'btn btn-default'}>Next Page</button>
              </VisibleEvent>
            ) : (
              <Link to={"/tags"} className={'btn btn-default scroll-top'}>View more</Link>
            )}
          </div>
        )}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state, ownProps) {
  if (!state.tag || !state.tag[ownProps.slug]) {
    return { data: { loading: true }};
  }

  return { data: state.tag[ownProps.slug] };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchTag }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TagOverviewList));