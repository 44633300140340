import React from 'react';
import {bindActionCreators} from "redux";
import {fetchTopics} from "../../../redux/topics";
import {connect} from "react-redux";
import BaseList from "../utility/BaseList";
import Loader from "../../shared/Loader";
import MixedContainer from "../utility/MixedContainer";

class List extends BaseList {
  loadObjects() {
    this.props.fetchTopics({
      sort: this.props.sort,
      index: this.props.index,
      tag: this.props.tag,
      page: this.state.page,
      limit: this.props.limit
    }).then(() => {
      this.setState({ loading: false });
    });
  }

  render() {
    const { data } = this.props;

    if (data.loading) {
      return <Loader inline={true} />;
    }

    return (
      <div className={'row full-gutters'}>
        {data.objects.map((object) => {
          return (
            <div key={object.type + "-" + (object.slug || object.id)} className={this.columnClasses()}>
              <MixedContainer object={object.preview} link={false} fullUrl={"/topic/" + object.slug} />
              <h3 className={'text-center mt-1'}>{object.title}</h3>
            </div>
          )
        })}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  if (!state.topics) {
    return { data: { loading: true } };
  }

  return { data: { loading: false, ...state.topics } };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchTopics }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(List);