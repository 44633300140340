import React from "react"
import { withRouter } from "react-router-dom";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import {fetchVideo} from "../../../redux/videos";

import Loader from "../../shared/Loader";
import ObjectList from "../shared/ObjectList";
import Container from "../video/Container";
import Content from "../video/Content";
import HostedImage from "../../shared/HostedImage";
import SocialBar from "../utility/SocialBar";
import VoteButton from "../shared/VoteButton";
import ReactionBar from "../shared/ReactionBar";
import NativeAds from "../../shared/ads/NativeAds";
import ShareBar from "../shared/ShareBar";
import SwitchingBanner from "../../shared/ads/SwitchingBanner";

class VideoShow extends React.Component {
  static defaultState = {
    thumbnails: false
  };

  constructor(props) {
    super(props);
    this.state = VideoShow.defaultState;
  }

  componentDidMount() {
    if (this.props.video.loading) {
      this.loadVideo();
    }
    _sp.changeMeta(this.props.video);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.match.params.slug !== this.props.match.params.slug) {
      this.loadVideo();
    }
    _sp.changeMeta(this.props.video);
  }

  loadVideo() {
    this.props.fetchVideo(this.props.match.params.slug, {
      related: 16
    });

    this.setState(VideoShow.defaultState)
  }

  render () {
    const { video } = this.props;

    if (video.loading) {
      return <Loader />;
    }

    let related = video.related;
    let topRelated = 4;

    return (
      <div className={'video-show'}>
        <div className={'light-container'}>
          <div className={'container'}>
            <div className={'row'}>
              <div className={'col-12 col-lg-8'}>
                <Content key={video.slug} video={video} advert={"preroll"} />

                <div className={'row mt-3'}>
                  <div className={'col-6 col-lg-4 offset-lg-2'}><VoteButton objectType={"video"} object={video} type={"like"} className={"btn-block"} /></div>
                  <div className={'col-6 col-lg-4'}><VoteButton objectType={"video"} object={video} type={"dislike"} className={"btn-block"} /></div>
                  <div className={'col-12 col-lg-8 offset-lg-2'}><ReactionBar object={video} objectType={'video'} /></div>
                  <div className={'col-12 col-lg-8 offset-lg-2'}><ShareBar object={video} /></div>
                  <div className={'col-12 mt-3'}><NativeAds cacheKey={video.slug} type={'video-show'} /></div>
                </div>

                <SocialBar object={video} type={'video'} className={'mb-3'} />
              </div>
              <div className={'col-12 col-lg-4'}>
                <SwitchingBanner desktopBanner={"300x250"} section={'video-show'} />
                <h3 className={'section-headline'}>More Videos</h3>
                {related && related.length > 0 && (
                  <div className={'row full-gutters'}>
                    {related.slice(0, topRelated).map((video) => {
                      return (
                        <div className={'col-6'} key={video.slug}>
                          <Container video={video} mixed={true} title={true} />
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className={'container'}>
          <h1 className={'mt-3 mb-3'}>{video.title ? video.title : "Untitled"}</h1>

          {video.tags && video.tags.filter(t => ["category", "gay", "lesbian"].includes(t.tag_type)).length > 0 && <div className={'object-list'}><strong>Categories:</strong> <ObjectList url={"/videos/category/"} objects={video.tags.filter(t => ["category", "gay", "lesbian"].includes(t.tag_type)) } /></div>}
          {video.pornstars && video.pornstars.length > 0 && <div className={'object-list'}><strong>Pornstars:</strong> <ObjectList url={"/pornstar/"} objects={video.pornstars} /></div>}
          {video.tags && video.tags.filter(t => t.tag_type === "default").length > 0 && <div className={'object-list'}><strong>Tags:</strong> <ObjectList url={"/videos/category/"} objects={video.tags.filter(t => t.tag_type === "default") } /></div>}

          {video.images && video.images.length > 0 && (
            <React.Fragment>
              <h3 className={'section-headline mt-4'}>Thumbnails</h3>
              {!this.state.thumbnails ? (
                <button onClick={() => { this.setState({ thumbnails: true })}} className={'btn btn-default'}>Show Thumbnails</button>
              ) : (
                <div className={'row full-gutters'}>
                  {video.images.map((image, index) => {
                    return (
                      <div className={'col-4 col-lg-2'} key={'image-' + index}>
                        <HostedImage image={image} size={'large'} />
                      </div>
                    )
                  })}
                </div>
              )}
            </React.Fragment>
          )}

          <h3 className={'section-headline mt-4'}>Related Videos</h3>
          {related && related.length > 0 && (
            <div className={'row full-gutters'}>
              {related.slice(topRelated, related.length).map((video) => {
                return (
                  <div className={'col-6 col-lg-2'} key={video.slug}>
                    <Container video={video} mixed={true} title={true} />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  let slug = ownProps.match.params.slug;
  if (state.video && state.video.slug === slug) {
    return { video: state.video };
  }

  if (state.all && state.all.videos && state.all.videos[slug]) {
    return { video: { loading: false, ...state.all.videos[slug] } };
  }

  return { video: { loading: true } };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchVideo }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VideoShow));