import React from "react"
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";

import List from "../video/List";
import Sidebar from "../layout/Sidebar";
import FeatureArea from "../layout/FeatureArea";

class VideoIndex extends React.Component {
  render () {
    let index, title, headline;

    switch (this.props.type) {
      case "tag":
        index = "tag-" + this.props.match.params.slug;
        title = `Watch hot ${this.props.match.params.slug} videos`;
        headline = `New ${this.props.match.params.slug} videos`;
        break;
      default:
        index = "overview-new";
        title = "Watch hot porn videos";
        headline = `New videos`;
        break;
    }

    return (
      <React.Fragment>
        <FeatureArea index={index} title={title} type={'videos'} tag={this.props.match.params.slug} sort={"popular"} />

        <div className={'container'}>
          <div className={'row'}>
            <div className={'col-12 col-lg-2'}>
              <Sidebar type={"videos"} tag={this.props.match.params.slug} />
            </div>
            <div className={'col-12 col-lg-10'}>
              <h2 className="section-headline">{headline}</h2>

              <List key={index} sort={this.props.type} tag={this.props.match.params.slug} index={index} limit={24} paginated={true} />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

VideoIndex.propTypes = {
  type: PropTypes.string
};

export default withRouter(VideoIndex);
