import React, {Component} from 'react';
import { Link } from "react-router-dom";

class Facets extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    this.setState({ loading: false });
  }

  buildQuery(index, value) {
    let params = _sp.dom.getAllParameters();
    value = encodeURI(value);

    if (!params[index]) {
      params[index] = value;
    } else {
      let split = params[index].split(",");
      if (split.indexOf(value) > -1) {
        split.remove(value);
      } else {
        split.push(value);
      }

      params[index] = split.join(",");
    }

    return _sp.dom.buildQueryString(params);
  }

  isActive(index, value) {
    let params = _sp.dom.getAllParameters();
    if (!params[index]) {
      return false;
    }

    value = encodeURI(value);

    return params[index].split(",").indexOf(value) > -1;
  }

  render() {
    const { name, data } = this.props;

    if (this.state.loading || data.length <= 0) {
      return "";
    }

    let title = name;
    switch (name) {
      case "tag_names":
        title = "Tags";
        break;
      case "pornstar_names":
        title = "Pornstars";
        break;
      case "type":
        title = "Type";
        break;
    }

    return (
      <div className={'facet-container'}>
        <h5 className={'section-headline'}>{title}</h5>

        <div className={'wrapper'}>
          {data.map((facet) => {
            return <Link className={this.isActive(title.toLowerCase(), facet.key) ? 'active' : ''} to={"/search?" + this.buildQuery(title.toLowerCase(), facet.key)} key={facet.key}>{facet.key.capitalize()} ({facet.doc_count})</Link>
          })}
        </div>
      </div>
    );
  }
}

export default Facets;