import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";

import {fetchSearch} from "../../../redux/search";
import Loader from "../../shared/Loader";
import MixedContainer from "../utility/MixedContainer";
import Pagination from "../utility/Pagination";
import Facets from "../search/Facets";
import SearchForm from "../layout/SearchForm";

class SearchIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: (this.props.match.params.page * 1) || 1,
      query: "",
      tags: "",
      pornstars: "",
      type: "",
      loading: true
    };

    this.loadParams = this.loadParams.bind(this);
  }

  componentDidMount() {
    this.setState(this.loadParams(), () => {
      this.loadSearch();
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let state = { loading: false };

    let query = _sp.dom.getParameterByName('query');
    if (query !== this.state.query) {
      state.query = query;
      state.loading = true;
    }

    let tags = _sp.dom.getParameterByName('tags');
    if (tags !== this.state.tags) {
      state.tags = tags;
      state.loading = true;
    }

    let pornstars = _sp.dom.getParameterByName('pornstars');
    if (pornstars !== this.state.pornstars) {
      state.pornstars = pornstars;
      state.loading = true;
    }

    let type = _sp.dom.getParameterByName('type');
    if (type !== this.state.type) {
      state.type = type;
      state.loading = true;
    }

    let page = (this.props.match.params.page * 1) || 1;
    if (page !== this.state.page) {
      state.page = page;
      state.loading = true;
    }

    if (state.loading) {
      this.setState(state, () => {
        this.loadSearch();
      });
    }
  }

  loadParams() {
    return {
      query: _sp.dom.getParameterByName('query'),
      tags: _sp.dom.getParameterByName('tags'),
      pornstars: _sp.dom.getParameterByName('pornstars'),
      type: _sp.dom.getParameterByName('type'),
    }
  }

  loadSearch() {
    let query = this.state.query;
    if (!query || query === "") {
      query = "*";
    }

    this.props.fetchSearch({
      query: query,
      tags: this.state.tags,
      pornstars: this.state.pornstars,
      type: this.state.type,
      page: this.state.page,
      limit: 48
    }).then(() => {
      this.setState({ loading: false });
    });
  }

  render() {
    const { data } = this.props;

    if (data.loading || this.state.loading) {
      return <Loader inline={true} />
    }

    return (
      <div className={'search-page'}>
        <div className={'container'}>
          <h1 className={'section-headline'}>Your search for {this.state.query} returned {data.pagination.total_count.toLocaleString()} results</h1>

          <div className={'search-filter'}>
            <h3>Filter your Search</h3>
            <div className={'row'}>
              <div className={'col-12 col-lg-6 offset-lg-3'}>
                <SearchForm buildParams={this.loadParams} />
              </div>
            </div>

            <div className={'row mb-4'}>
              {Object.keys(data.aggs).map((key) => {
                return (
                  <div key={key} className={'col-12 col-lg-4'}>
                    <Facets name={key} data={data.aggs[key]} />
                  </div>
                )
              })}
            </div>
          </div>

          {data.pagination.total_count === 0 ?
            (
              <div>
                No results found
                <Link className={'btn btn-default btn-block mt-3'} to={"/search"}>Reset your Search</Link>
              </div>
            ) : (
              <React.Fragment>
                <div className={'row full-gutters'}>
                  {data.objects.map((object) => {
                    return (
                      <div key={object.type + "-" + (object.slug || object.id)} className={'col-6 col-lg-3'}>
                        <MixedContainer object={object} />
                      </div>
                    )
                  })}
                </div>

                <Pagination pages={data.pagination} baseUrl={'/search'} params={this.state} />
              </React.Fragment>
            )
          }
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return { data: state.search };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchSearch }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchIndex));