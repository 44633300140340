import React, {Component} from 'react';
import ReactDOM from 'react-dom'
import PropTypes from "prop-types";

class Overlay extends Component {
  constructor(props) {
    super(props);

    this._lastHeight = 0;
  }

  componentDidMount() {
    this._element = document.querySelector('.content-overlay-body');
    document.body.classList.add("noscroll");

    if (settings.os === "iOS") {
      document.body.classList.add("fixed");
    }

    this.calculateHeight();

    this._heightInterval = setInterval(() => {
      this.calculateHeight()
    }, 500);
  }

  componentWillUnmount() {
    document.body.classList.remove("noscroll");
    if (settings.os === "iOS") {
      document.body.classList.remove("fixed");
    }
    clearInterval(this._heightInterval);
  }

  calculateHeight() {
    if (this._lastHeight === window.innerHeight) {
      return;
    }

    if (this._element) {
      this._element.style.height = "auto";

      let height = this._element.clientHeight;
      if (height + this._element.offsetTop > window.innerHeight) {
        height = (window.innerHeight - (this._element.offsetTop * 2));
      }
      this._element.style.height = height + "px";

      this._lastHeight = window.innerHeight;
    }
  }

  render() {
    return ReactDOM.createPortal(
      <React.Fragment>
        <div className={'content-overlay'} onClick={this.props.clickCallback} />
        {this.props.children}
      </React.Fragment>
      , document.body);
  }
}

Overlay.propTypes = {
  clickCallback: PropTypes.func
};

export default Overlay;