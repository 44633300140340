import React from "react"
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link, withRouter } from "react-router-dom";

import { fetchTags } from "../../../redux/tags";
import Container from "./Container";
import VisibleEvent from "../../shared/VisibleEvent";
import Loader from "../../shared/Loader";
import BaseList from "../utility/BaseList";

class List extends BaseList {
  loadObjects(opts={}) {
    this.props.fetchTags({
      sort: this.props.sort,
      page: this.state.page,
      limit: this.props.limit,
      index: this.props.index
    }).then(() => {
      this.setState({ loading: false });
    });
  }

  render () {
    const { data } = this.props;

    if (data.loading) {
      return <Loader inline={true} />;
    }

    return (
      <React.Fragment>
        <div className={'row full-gutters'}>
          {!data.objects || data.pagination.total_count <= 0 ? (
            <div>No Tags found :(</div>
          ) : (
            data.objects.map((tag) => {
              return (
                <div key={tag.slug} className={this.columnClasses()}>
                  <Container tag={tag} />
                </div>
              )
            })
          )}
        </div>
        {data.pagination.next_page && (
          <div className={'text-center mt-3'}>
            {this.props.paginated ? (
              <VisibleEvent key={"page-" + data.pagination.current_page} inViewport={this.visitNextPage} offset={1000}>
                <button ref={(e) => { this._nextPageButton = e; }} onClick={this.visitNextPage} className={'btn btn-default'}>Next Page</button>
              </VisibleEvent>
            ) : (
              <Link to={"/tags"} className={'btn btn-default scroll-top'}>View more</Link>
            )}
          </div>
        )}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state, ownProps) {
  let index = ownProps.index + "-" + ownProps.sort;

  if (!state.tags || !state.tags[index]) {
    return { data: { loading: true } };
  }

  return { data: state.tags[index] };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchTags }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(List));