import React, {Component} from 'react';
import {Helmet, HelmetProvider} from "react-helmet-async";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

class MetaTags extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.onMetaTagChange = this.onMetaTagChange.bind(this);
    this.onUrlChange = this.onUrlChange.bind(this);
  }

  componentDidMount() {
    window.addEventListener('urlChange', this.onUrlChange);
    window.addEventListener('changeMeta', this.onMetaTagChange);
  }

  componentWillUnmount() {
    window.removeEventListener('urlChange', this.onUrlChange);
    window.removeEventListener('changeMeta', this.onMetaTagChange);
  }

  onMetaTagChange(e) {
    this.setState({ meta: e.detail.meta })
  }

  onUrlChange() {
    this.setState({ meta: undefined })
  }

  render() {
    const { meta } = this.props;

    if (!meta) {
      return "";
    }

    let tags = meta;
    if (this.state.meta) {
      tags = this.state.meta;
    }

    if (tags.default && (!tags.description || this.props.renderDefault)) {
      tags = tags.default;
    }

    return (
      <HelmetProvider>
        <Helmet key={Math.random().toString(36).substring(2, 15)}>
          {tags.title && <title>{tags.title}</title>}
          {tags.description && <meta name="description" content={tags.description} />}
          {tags.image_src && <link rel="image_src" href={tags.image_src} />}
          {tags.og && tags.og.title && <meta property="og:title" content={tags.og.title} />}
          {tags.og && tags.og.type && <meta property="og:type" content={tags.og.type} />}
          {tags.og && tags.og.url && <meta property="og:url" content={tags.og.url} />}
          {tags.og && tags.og.image && <meta property="og:image" content={tags.og.image} />}
          {tags.og && tags.og.description && <meta property="og:description" content={tags.og.description} />}
        </Helmet>
      </HelmetProvider>
    );
  }
}

function mapStateToProps(state, ownProps) {
  if (!state.all || !state.all.meta) {
    return { meta: {} };
  }

  return { meta: state.all.meta };
}

export default withRouter(connect(mapStateToProps)(MetaTags));