import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {fetchTopic} from "../../../redux/topics";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Loader from "../../shared/Loader";
import MixedContainer from "../utility/MixedContainer";
import Pagination from "../utility/Pagination";

class TopicShow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: (this.props.match.params.page * 1) || 1
    }
  }

  componentDidMount() {
    this.props.fetchTopic(this.props.match.params.slug, {page: this.state.page});
    _sp.changeMeta(this.props.data);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let page = (this.props.match.params.page * 1) || 1;
    if (page !== this.state.page) {
      this.setState({ page: page }, () => {
        this.props.fetchTopic(this.props.match.params.slug, {page: page});
      });
    }
  }

  render() {
    const { data } = this.props;

    if (data.loading) {
      return <Loader/>
    }

    return (
      <div className={'search-page'}>
        <div className={'container'}>
          <h1 className={'section-headline'}>{data.topic.title}</h1>

          <div className={'row full-gutters'}>
            {data.objects.map((object) => {
              return (
                <div className={'col-6 col-lg-3'} key={object.type + (object.slug || object.id)}>
                  <MixedContainer object={object} />
                </div>
              )
            })}
          </div>

          <Pagination pages={data.pagination} baseUrl={"/topic/" + data.topic.slug} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return { data: state.topic };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchTopic }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TopicShow));