import React, {Component} from 'react';
import VideoContainer from "../video/Container";
import ImageContainer from "../image/Container";
import GalleryContainer from "../gallery/Container";
import CamContainer from "../cam/Container";
import PornstarContainer from "../pornstar/Container";

class MixedContainer extends Component {
  generateUrl() {
    const { object } = this.props;

    let type = object.type;
    if (this.props.type) {
      type = this.props.type;
    }

    if (this.props.fullUrl) {
      return this.props.fullUrl;
    }

    if (this.props.baseUrl) {
      return this.props.baseUrl + (object.slug || object.id);
    }

    switch (type) {
      case "Image":
        return "/image/" + (object.slug || object.id);
      case "Video":
        return "/video/" + object.slug;
      case "Gallery":
        return "/gallery/" + object.slug;
      case "Cam":
        return "/cam/" + object.provider + "/" + object.username;
      case "Pornstar":
        return "/pornstar/" + object.slug;
      default:
        return "";
    }
  }

  render() {
    const { object } = this.props;

    if (!object) {
      return "";
    }

    let type = object.type;
    if (this.props.type) {
      type = this.props.type;
    }

    switch (type) {
      case "Image":
        return <ImageContainer image={object} mixed={true} title={false} fullUrl={this.generateUrl()} />;
      case "Video":
        return <VideoContainer video={object} mixed={true} title={false} fullUrl={this.generateUrl()} />;
      case "Gallery":
        return <GalleryContainer gallery={object} mixed={true} title={false} fullUrl={this.generateUrl()} />;
      case "Cam":
        return <CamContainer cam={object} mixed={true} title={false} fullUrl={this.generateUrl()} />;
      case "Pornstar":
        return <PornstarContainer pornstar={object} mixed={true} title={false} fullUrl={this.generateUrl()} />;
      default:
        return "";
    }
  }
}

export default MixedContainer;