import React, {Component} from 'react';
import { withRouter } from "react-router-dom";

import Sidebar from "../layout/Sidebar";
import FeatureArea from "../layout/FeatureArea";
import List from "../gallery/List";

class GalleryIndex extends Component {
  render () {
    let index, title, headline;

    switch (this.props.type) {
      case "tag":
        index = "tag-" + this.props.match.params.slug;
        title = `Watch hot ${this.props.match.params.slug} galleries`;
        headline = `New ${this.props.match.params.slug} Galleries`;
        break;
      default:
        index = "overview-new";
        title = "Watch hot cosplay porn galleries";
        headline = "New Galleries";
        break;
    }

    return (
      <React.Fragment>
        <FeatureArea index={index} title={title} type={'galleries'} tag={this.props.match.params.slug} sort={"popular"} />

        <div className={'container'}>
          <div className={'row'}>
            <div className={'col-12 col-lg-2'}>
              <Sidebar type={'galleries'} />
            </div>
            <div className={'col-12 col-lg-10'}>
              <h2 className="section-headline">{headline}</h2>

              <List key={index} sort={this.props.type} tag={this.props.match.params.slug} index={index} limit={24} paginated={true} />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(GalleryIndex);