import {applyMiddleware, combineReducers, compose, createStore} from 'redux'
import thunk from 'redux-thunk';
import createRootReducer from './reducers'
// import { routerMiddleware } from 'connected-react-router'

const configureStore = (history, initialState = {}) => {
  const store = createStore(
    createRootReducer(history),
    initialState,
    applyMiddleware(thunk),
  );

  return store;
};

export default configureStore;