import React from "react"
import Loader from "./Loader";

import placeholder from 'images/placeholder-small.png';

class HostedImage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true
    };

    this.onLoad = this.onLoad.bind(this);
  }

  componentDidMount() {
    if (this.props.showLoader) {
      let i = new Image();
      i.onload = this.onLoad;
      i.src = this.imageUrl();
    }
  }

  onLoad() {
    this.setState({ loading: false });
  }

  imageUrl() {
    const { image, size } = this.props;

    if (image.image_type === 'remote' || this.props.mixed) {
      return (image.url || image.sizes[(size || "small_square")]).replace("http:", "https:");
    }

    return image.sizes[(size || "small_square")].replace("http:", "https:");
  }

  render () {
    const { image } = this.props;

    if (!image) {
      return "";
    }

    if (this.props.showLoader && this.state.loading) {
      return <Loader inline={true} />
    }

    if (image.type && image.type === "Video") {
      if (!image.images || image.images.length <= 0) {
        return "";
      }

      return <HostedImage {...this.props} image={image.images[0]} />
    }

    if (image.image_type === 'remote' || this.props.mixed) {
      let url = placeholder;
      if (this.props.force && this.props.force === true) {
        url = this.imageUrl();
      }

      let style = {
        backgroundImage: "url('" + url + "')"
      };

      let classes = ["lazyload", "responsive-container", "horizontal"];
      if (this.props.className) {
        classes.push(this.props.className);
      }

      return (
        <div className={classes.join(" ")} style={style} data-bg={this.imageUrl()} />
      )
    }

    let classNames = ['img-fluid', "lazyload"];
    if (this.props.className) {
      classNames.push(this.props.className);
    }

    if (this.props.force) {
      return <img src={this.imageUrl()} data-src={this.imageUrl()} className={classNames.join(" ")} alt={this.props.alt} />
    }

    return <img src={placeholder} data-src={this.imageUrl()} className={classNames.join(" ")} alt={this.props.alt} />
  }
}

export default HostedImage
