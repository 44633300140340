import React, {Component} from 'react';
import { withRouter } from "react-router-dom";

import TagOverviewList from "../tag/TagOverviewList";

class TagShow extends Component {
  componentDidMount() {
    _sp.storage.set('image-tag', this.props.match.params.slug, "session");
    _sp.storage.set('image-sort', "top", "session");
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    _sp.storage.set('image-tag', this.props.match.params.slug, "session");
    _sp.storage.set('image-sort', "top", "session");
  }

  render() {
    let slug = this.props.match.params.slug;

    return (
      <div className={'container'}>
        <div className={'row'}>
          <div className={'col-12'}>
            <TagOverviewList key={slug} limit={40} rowCount={4} slug={slug} paginated={true} />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(TagShow);