import React, {Component} from 'react';
import PropTypes from "prop-types"
import { Link } from 'react-router-dom';

import HostedImage from "../../shared/HostedImage";
import ReactionCounts from "../shared/ReactionCounts";

class Container extends Component {
  static defaultProps = {
    mixed: false,
    title: true
  };

  render() {
    const { gallery } = this.props;

    return (
      <article className={'object-container'}>
        <Link to={this.props.fullUrl || `/gallery/${gallery.slug}`} className={'image-container'} title={gallery.title}>
          <HostedImage image={gallery.preview} mixed={false} className={'full'} alt={gallery.title} mixed={this.props.mixed} />
          {this.props.mixed && <i className={'fas fa-images icon'} />}
          <ReactionCounts object={gallery} />
        </Link>
        {(!this.props.mixed || this.props.title) && (
          <div className={'info-row'}>
            <div className={'utility'}>
              <i className={'far fa-image'} /> {gallery.image_count.toLocaleString()}
            </div>

            <h3><Link to={`/gallery/${gallery.slug}`} title={gallery.title}>{gallery.title}</Link></h3>
          </div>
        )}
      </article>
    );
  }
}

Container.propTypes = {
  gallery: PropTypes.object,
  mixed: PropTypes.bool
};

export default Container;