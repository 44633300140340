import React, {Component} from 'react';
import {connect} from "react-redux";

class Footer extends Component {
  render() {
    const { navigation } = this.props;

    return (
      <footer className={'page-footer'}>
        <div className={'container'}>
          {navigation.footer.map((nav) => {
            return <a key={nav.url} href={nav.url} target={nav.target}>{nav.title}</a>
          })}
        </div>
      </footer>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return { navigation: state.navigation };
}

export default connect(mapStateToProps)(Footer);