import React, {Component} from 'react';

import { Link } from "react-router-dom";

class ObjectList extends Component {
  render() {
    const { objects, url } = this.props;

    if (objects.length === 0) {
      return "";
    }

    return objects.sort((a, b) => a.name > b.name ? 1 : -1).map((object) => {
      return <Link key={object.slug} to={url + object.slug} rel={'tag'}>{object.name}</Link>
    }).toSentence();
  }
}

export default ObjectList;