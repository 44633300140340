import React from "react"
import PropTypes from 'prop-types';
import {NavLink, withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import {fetchSidebar} from "../../../redux/sidebar";
import {connect} from "react-redux";
import Loader from "../../shared/Loader";
import AggContainer from "../utility/AggContainer";

class Sidebar extends React.Component {
  static defaultProps = {
    sort: "new"
  };

  componentDidMount() {
    if (this.props.loading) {
      this.loadSidebar();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.type !== prevProps.type || this.props.tag !== prevProps.tag || this.props.sort !== prevProps.sort) {
      this.loadSidebar();
    }
  }

  loadSidebar() {
    this.props.fetchSidebar({
      type: this.props.type,
      sort: this.props.sort,
      aggs: { aggTag: this.props.tag }
    });
  }

  render () {
    let url;
    switch (this.props.type) {
      case "images":
        url = "/images/category/";
        break;
      case "videos":
        url = "/videos/category/";
        break;
      case "galleries":
        url = "/galleries/category/";
        break;
      case "cams":
        url = "/cams/category/";
        break;
    }

    let sorting = "";
    if (this.props.sort === "top") {
      sorting = "/top";
    }

    return (
      <div className={'sidebar'}>
        <h3>Filter by Category</h3>

        <div className={'collapsable'}>
          {this.props.loading ? (
            <Loader inline={true} />
          ) : (
            <React.Fragment>
              <div className={'row'}>
                {(this.props.tags || []).map((tag) => {
                  return (
                    <div key={tag.slug} className={'col-6 col-sm-4 col-lg-12'}>
                      <NavLink onClick={() => { document.querySelector('.sidebar').classList.remove('expanded') }} to={url + tag.slug + sorting} activeClassName={'active'}>
                        {tag.name} ({tag.result_count})
                      </NavLink>
                    </div>
                  );
                })}
              </div>
              <AggContainer type={this.props.type} index={"duration"} aggs={this.props.aggs} title={"Filter by Duration"} />
              <AggContainer type={this.props.type} index={"provider"} aggs={this.props.aggs} title={"Filter by Provider"} />
            </React.Fragment>
          )}
        </div>

        <button className={'btn btn-default btn-block btn-uncollapse'} onClick={() => { document.querySelector('.sidebar').classList.toggle('expanded') }} />
      </div>
    );
  }
}

Sidebar.propTypes = {
  type: PropTypes.string
};

function mapStateToProps(state, ownProps) {
  let index = ownProps.type + "-" + (ownProps.sort || "new");

  if (!state.sidebar[index]) {
    return { loading: true };
  }

  return state.sidebar[index];
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchSidebar }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Sidebar));