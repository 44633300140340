import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {fetchGallery} from "../../../redux/gallery";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";

import SwipeArea from "../utility/SwipeArea";
import HostedImage from "../../shared/HostedImage";
import Loader from "../../shared/Loader";
import Container from '../gallery/Container';
import Banner from "../../shared/ads/Banner";
import SwitchingBanner from "../../shared/ads/SwitchingBanner";
import NativeAds from "../../shared/ads/NativeAds";

class GalleryImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentIndex: this.props.match.params.index * 1,
      thumbnailIndex: Math.ceil((this.props.match.params.index * 1) / this.perPage())
    };

    this.displayIndex = this.displayIndex.bind(this);
    this.showPreviousThumbnails = this.showPreviousThumbnails.bind(this);
    this.showNextThumbnails = this.showNextThumbnails.bind(this);
    this.onResize = this.onResize.bind(this);
  }

  componentDidMount() {
    if (this.props.gallery.loading) {
      this.loadGallery();
    } else {
      this.preloadImage(this.state.currentIndex);
    }

    this.onResize();
    window.addEventListener('resize', this.onResize);
    _sp.changeMeta(this.props.gallery);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.match.params.slug !== this.props.match.params.slug) {
      this.setState({ currentIndex: this.props.match.params.index * 1, thumbnailIndex: Math.ceil((this.props.match.params.index * 1) / this.perPage()) });
      this.loadGallery();
    }

    if (prevProps.match.params.index !== this.props.match.params.index) {
      this.setState({ currentIndex: (this.props.match.params.index * 1), thumbnailIndex: Math.ceil((this.props.match.params.index * 1) / this.perPage()) }, () => {
        this.preloadImage(this.state.currentIndex);
      });
    }
    _sp.changeMeta(this.props.gallery);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  onResize() {
    if (typeof(window) !== "object") {
      return;
    }

    this.setState({
      perPage: window.innerWidth < 768 ? 4 : 12
    })
  }

  preloadImage(index) {
    let image = this.props.gallery.images[index];
    if (!image) {
      return;
    }

    let i = new Image();
    i.src = image.sizes.full;
  }

  loadGallery() {
    this.props.fetchGallery(this.props.match.params.slug, {
      related: 12
    }).then(() => {
      this.preloadImage(this.state.currentIndex);
    });
  }

  displayIndex(index) {
    if (!this.props.gallery.images[index - 1]) {
      return;
    }

    _sp.visit(`/gallery/${this.props.gallery.slug}/image/${index}`);
  }

  showPreviousThumbnails() {
    if (this.state.thumbnailIndex - 1 < 1) {
      return;
    }

    this.setState({ thumbnailIndex: this.state.thumbnailIndex - 1 })
  }

  showNextThumbnails() {
    if (this.state.thumbnailIndex + 1 > this.pageCount()) {
      return;
    }

    this.setState({ thumbnailIndex: this.state.thumbnailIndex + 1 })
  }

  pageCount() {
    const { gallery } = this.props;

    let pages = Math.ceil(gallery.images.length / this.perPage());
    if (pages <= 0) {
      pages = 1;
    }
    return pages;
  }

  perPage() {
    if (typeof(window) === "object") {
      return window.innerWidth < 768 ? 4 : 12;
    }

    if (this.state && this.state.perPage) {
      return this.state.perPage;
    }

    return 12;
  }

  render() {
    const { gallery } = this.props;

    if (gallery.loading) {
      return <Loader/>
    }

    return (
      <div className={'gallery-image'}>
        <div className={'light-container'}>
          <div className={'container'}>
            <div className={'row'}>
              <div className={'col-12 col-lg-8 col-xl-9'}>
                <SwitchingBanner cacheKey={this.state.currentIndex + ""} desktopBanner={"468x60"} className={"mb-3"} section={'gallery-image'} />

                <div id={'primary-image'}>
                  <SwipeArea onLeft={() => { this.displayIndex(this.state.currentIndex - 1) }} onRight={() => { this.displayIndex(this.state.currentIndex + 1) }} onSwipeLeft={() => { this.displayIndex(this.state.currentIndex + 1) }} onSwipeRight={() => { this.displayIndex(this.state.currentIndex - 1) }}>
                    {this.state.currentIndex - 1 >= 1 && <Link to={`/gallery/${gallery.slug}/image/${this.state.currentIndex - 1}`} className={'prev'}><i className={'fas fa-chevron-left'} /></Link>}
                    <HostedImage key={this.state.currentIndex} showLoader={true} image={gallery.images[this.state.currentIndex - 1]} size={"full"} />
                    {this.state.currentIndex + 1 <= gallery.images.length && <Link to={`/gallery/${gallery.slug}/image/${this.state.currentIndex + 1}`} className={'next'}><i className={'fas fa-chevron-right'} /></Link>}
                  </SwipeArea>
                </div>

                <div className={'mt-3'}><NativeAds type={'gallery-image'} /></div>
              </div>
              <div className={'col-12 col-lg-4 col-xl-3'}>
                <div className={'row full-gutters'}>
                  {gallery.images.map((image, index) => {
                    return (
                      <div className={'col-6 col-md-4 thumbnail-page ' + (Math.floor(index / this.perPage()) + 1 === this.state.thumbnailIndex ? "visible" : "")} key={"thumbnail-" + this.state.thumbnailIndex + index}>
                        <Link to={`/gallery/${gallery.slug}/image/${index + 1}`} className={this.state.currentIndex - 1 === index ? "active" : ""}>
                          <HostedImage image={image} size={"small_square"} />
                        </Link>
                      </div>
                    )
                  })}
                </div>
                <div className={'row full-gutters'}>
                  <div className={'col-6'}>
                    {this.state.thumbnailIndex > 1 && <button className={'btn btn-block btn-default'} onClick={this.showPreviousThumbnails}><i className={'fas fa-chevron-left'} /></button>}
                  </div>
                  <div className={'col-6'}>
                    {this.state.thumbnailIndex < this.pageCount() && <button className={'btn btn-block btn-default'} onClick={this.showNextThumbnails}><i className={'fas fa-chevron-right'} /></button>}
                  </div>
                  <div className={'col-12'}>
                    <Link to={`/gallery/${gallery.slug}`} className={'btn btn-default btn-block'}>Overview</Link>
                  </div>
                </div>

                <Banner cacheKey={this.state.currentIndex} size={"300x250"} type={"gallery"} section={'gallery-image'} />
              </div>
            </div>
          </div>
        </div>

        <div className={'container'}>
          <h1 className={'mt-3 mb-3'}>{gallery.title} - Image {this.state.currentIndex + 1}</h1>

          <h3 className={'section-headline mt-4'}>Related Galleries</h3>
          {gallery.related && gallery.related.length > 0 && (
            <div className={'row full-gutters'}>
              {gallery.related.map((gallery) => {
                return (
                  <div className={'col-6 col-lg-2'} key={gallery.slug}>
                    <Container gallery={gallery} />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  let slug = ownProps.match.params.slug;

  if (state.all && state.all.galleries && state.all.galleries[slug]) {
    return { gallery: { loading: false, ...state.all.galleries[slug] } };
  }

  if (state.gallery && state.gallery.slug === slug) {
    return { gallery: { loading: false, ...state.gallery } };
  }

  return { gallery: { loading: true } };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchGallery }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GalleryImage));