import React, {Component} from 'react';
import { createBrowserHistory, createMemoryHistory } from 'history';

import configureStore from "../redux/store";

class BaseApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      location: this.props.location
    };

    this._history = typeof(window) === "object" && typeof(document) === "object" ? createBrowserHistory() : createMemoryHistory({ initialEntries: [this.state.location] });
    this._store = configureStore(this._history, this.generateInitial(this.props.initial));

    this.onVisitUrl = this.onVisitUrl.bind(this);
    this.onPreload = this.onPreload.bind(this);
  }

  generateInitial(values) {
    values.all = { aggs: {} };

    if (values.images) {
      values.all.meta = this.findInitalValue(values.images, "meta_tags");
    }

    if (values.galleries) {
      values.all.meta = this.findInitalValue(values.galleries, "meta_tags");
    }

    if (values.videos) {
      values.all.meta = this.findInitalValue(values.videos, "meta_tags");
      values.all.aggs.videos = this.findInitalValue(values.videos, "aggs");
    }

    if (values.cams) {
      values.all.meta = this.findInitalValue(values.cams, "meta_tags");
    }

    if (values.image) {
      values.all.images = {};
      values.all.images[values.image.id] = values.image;
      values.all.images[values.image.slug] = values.image;
      values.all.meta = values.image.meta_tags;
    }

    if (values.video) {
      values.all.videos = {};
      values.all.videos[values.video.slug] = values.video;
      values.all.meta = values.video.meta_tags;
    }

    if (values.gallery) {
      values.all.galleries = {};
      values.all.galleries[values.gallery.slug] = values.gallery;
      values.all.meta = values.gallery.meta_tags;
    }

    if (!values.all.meta) {
      values.all.meta = values.navigation.meta_tags;
    }

    /*if (values.posts) {
      for (let key of Object.keys(values.posts)) {
        if (!values.posts[key] || !values.posts[key].posts) {
          continue;
        }

        values.posts[key].posts.forEach(post => {
          values.all[post.slug] = post;
        });
      }
    }*/

    return values;
  }

  findInitalValue(values, index) {
    for (let key in values) {
      if (values[key][index]) {
        return values[key][index];
      }
    }

    return {};
  }

  componentDidMount() {
    window.addEventListener('visitUrl', this.onVisitUrl);

    this._unlisten = this._history.listen((e) => {
      window.dispatchEvent(new CustomEvent('urlChange', { detail: e }));
    });

    window.addEventListener('preloadData', this.onPreload);
  }

  componentWillUnmount() {
    window.removeEventListener('visitUrl', this.onVisitUrl);
    window.removeEventListener('preloadData', this.onPreload);

    this._unlisten();
  }

  onPreload(e) {
    let type = e.detail.type;
    let id = e.detail.id;
    let state = this._store.getState();

    if (type === "image") {
      let url;
      if (state.image && state.image.id === id) {
        url = state.image.sizes.full;
      }

      if (state.all && state.all.images && state.all.images[id]) {
        url = state.all.images[id].sizes.full;
      }

      let i = new Image();
      i.src = url;
    }
  }

  onVisitUrl(e) {
    e.detail.handled = true;
    this._history.push(e.detail.url);
  }
}

export default BaseApp;