import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import Overlay from "../porn_app/utility/Overlay";
import SearchForm from "../porn_app/layout/SearchForm";
import {fetchSidebar} from "../../redux/sidebar";

class Burger extends Component {
  constructor(props) {
    super(props);

    this.state = {
      objects: undefined,
      type: undefined
    };

    this.onClose = this.onClose.bind(this);
    this.calculateHeight = this.calculateHeight.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      this._element.classList.add("visible");
    }, 10);

    setTimeout(() => {
      this.calculateHeight();
    }, 310);

    this.bindCloseEvent();

    window.addEventListener('resize', this.calculateHeight);

    this._heightInterval = setInterval(() => {
      if (this._lastHeight !== window.innerHeight) {
        this.calculateHeight();
      }
    }, 500);

    this.loadTags();
  }

  componentWillUnmount() {
    _sp.dom.select('#burger-menu a, #burger-menu button').forEach((elem) => {
      elem.removeEventListener('click', this.onClose);
    });

    window.removeEventListener('resize', this.calculateHeight);

    clearInterval(this._heightInterval);
    clearTimeout(this._timeout)
  }

  onClose() {
    this._element.classList.remove("visible");
    this._timeout = setTimeout(this.props.closeCallback, 300);
  }

  calculateHeight() {
    this._lastHeight = window.innerHeight;

    let total = document.querySelector('#burger-menu').clientHeight;
    total -= document.querySelector('#burger-menu .section-links').clientHeight;
    total -= document.querySelector('#burger-menu .utility-links').clientHeight;
    total -= 80;

    let tags = document.querySelector('#burger-menu .tag-links');
    if (tags) {
      tags.style.height = total + "px";
    }
  }

  loadTags() {
    const { sidebar } = this.props;
    let type, objects;

    switch (window.location.pathname.split("/")[1]) {
      case "images":
      case "image":
        objects = sidebar["images-new"];
        type = "images";
        break;
      case "galleries":
      case "gallery":
        objects = sidebar["galleries-new"];
        type = "galleries";
        break;
      default:
        objects = sidebar["videos-new"];
        type = "videos";
        break;
    }

    if (!objects) {
      this.props.fetchSidebar({
        type: type
      }).then(() => {
        this.loadTags();
      });
    } else {
      this.setState({objects: objects, type: type}, () => {
        this.bindCloseEvent();
      });
    }
  }

  bindCloseEvent() {
    _sp.dom.select('#burger-menu a, #burger-menu button').forEach((elem) => {
      elem.addEventListener('click', this.onClose);
    });
  }

  render() {
    const { navigation } = this.props;
    const { objects, type } = this.state;

    return (
      <Overlay clickCallback={this.onClose}>
        <div id={'burger-menu'} ref={(e) => { this._element = e; }}>
          <button onClick={this.onClose} className={'close'}><i className={'fas fa-times'} /></button>
          <div className={'section-links'}>
            <NavLink exact={true} to={"/"} activeClassName={'active'} className={'scroll-top'}>Home</NavLink>
            {navigation.primary.map((nav) => {
              return <NavLink key={nav.url} to={nav.url} activeClassName={'active'} className={'scroll-top'}>{nav.title}</NavLink>
            })}
          </div>

          {type && objects && (
            <div className={'tag-links'}>
              {objects.tags.map((tag) => {
                return (
                  <NavLink key={'tag-link-' + tag.slug} to={"/" + type + "/category/" + tag.slug} className={'scroll-top'} activeClassName={'active'}>{tag.name}</NavLink>
                )
              })}
            </div>
          )}

          <div className={'utility-links'}>
            <SearchForm />
          </div>
        </div>
      </Overlay>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return { sidebar: state.sidebar, navigation: state.navigation };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchSidebar }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Burger);