import React, {Component} from 'react';
import VideoPlayer from "../utility/VideoPlayer";

class PreviewVideo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      wasVisible: false
    };
  }

  componentDidMount() {
    if (!this._player || !this._player.videoNode) {
      return;
    }

    if (this.props.visible) {
      this.setState({ wasVisible: true });
      this._player.play();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.visible !== this.props.visible) {
      if (!this._player || !this._player.videoNode) {
        return;
      }

      if (this.props.visible) {
        this.setState({ wasVisible: true });
        this._player.play();
      } else {
        this._player.pause();
      }
    }
  }

  render() {
    const { video, visible, display } = this.props;

    let classes = ["preview-video"];
    if (visible || display) {
      classes.push("visible");
    }

    let trailer = video.trailer.replace("http:", "https:");
    let preview = video.images[0];

    return (
      <div className={classes.join(" ")}>
        <VideoPlayer poster={preview && preview.sizes && preview.sizes.full} className={'no-ar'} preload={"none"} autoplay={false} loop={true} controls={false} muted={true} namespace={"preview"} sources={[{ src: trailer, type: "video/mp4" }]} ref={(e) => this._player = e} />
      </div>
    );
  }
}

export default PreviewVideo;