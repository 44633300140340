import React, {Component} from 'react';
import { Link, withRouter } from 'react-router-dom';
import {connect} from "react-redux";

class AggContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      siteTitle: "",
      mounted: false
    }
  }

  componentDidMount() {
    this.setState({
      siteTitle: settings.site_title,
      mounted: true
    })
  }

  url(param) {
    let split = param.split("=");

    if (_sp.dom.getParameterByName(split[0]) === split[1]) {
      let url = _sp.dom.updateQueryStringParameter(this.props.location.pathname + this.props.location.search, split[0], split[1]);
      url = url.replace("&" + split[0] + "=" + split[1], "");
      url = url.replace(split[0] + "=" + split[1], "");
      return url.replace("?&", "?");
    }

    return _sp.dom.updateQueryStringParameter(this.props.location.pathname + this.props.location.search, split[0], split[1]);
  }

  render() {
    const { title, index, type, aggs } = this.props;

    if (!this.state.mounted || !aggs || !aggs[index]) {
      return "";
    }

    let objects = aggs[index];

    let hosted = aggs["video_type"] ? aggs["video_type"].filter(a => a.name === "hosted")[0] : undefined;

    return (
      <div className={'agg-container'} key={new Date().getTime()}>
        <h3>{title}</h3>
        {index === "provider" && hosted && (
          <Link className={_sp.dom.getParameterByName(index) === 'hosted' ? 'active' : ''} key={hosted.key} to={this.url("provider=hosted")}>{this.state.siteTitle} ({hosted.count.toLocaleString()})</Link>
        )}

        {objects.map((agg) => {
          return <Link className={_sp.dom.getParameterByName(index) === agg.key.split("=")[1] ? 'active' : ''} key={agg.key} to={this.url(agg.key)}>{agg.name} ({agg.count.toLocaleString()})</Link>
        })}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return { aggs: state.all.aggs[ownProps.type] };
}

export default withRouter(connect(mapStateToProps)(AggContainer));