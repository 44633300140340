export const allReducer = (state = { videos: {} }, action) => {
  let all_posts = state;

  if (!all_posts.meta) {
    all_posts.meta = {};
  }

  if (!all_posts.aggs) {
    all_posts.aggs = {};
  }

  if (action && action.data && action.data.meta_tags && action.data.meta_tags.default) {
    // all_posts.meta = action.data.meta_tags.default;
    all_posts.meta = action.data.meta_tags;
  }

  switch (action.type) {
    case "IMAGE_LOADED":
      if (!all_posts.images) {
        all_posts.images = {};
      }

      all_posts.images[action.data.id] = action.data;
      all_posts.images[action.data.slug] = action.data;
      break;
    case "VIDEO_LOADED":
      if (!all_posts.videos) {
        all_posts.videos = {};
      }

      all_posts.videos[action.data.slug] = action.data;
      break;
    case "VIDEOS_LOADED":
      all_posts.aggs.videos = action.data.aggs;
      break;
    case "GALLERY_LOADED":
      if (!all_posts.galleries) {
        all_posts.galleries = {};
      }

      all_posts.galleries[action.data.slug] = action.data;
      break;
  }

  return Object.assign({}, all_posts);
};