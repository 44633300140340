export const fetchTopics = (opts={}) => (dispatch) => {
  let limit = opts.limit || 20;
  let page = opts.page || 1;

  return _sp.axios.get(settings.api_base + "/" + settings.api_version + '/topic', {
    params: {
      limit: limit,
      page: page
    }
  })
    .then(res => {
      return res.data;
    })
    .then(data => {
      dispatch({
        type: "TOPICS_LOADED",
        data: data,
      });
    })
};

export const topicsReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case "TOPICS_LOADED":
      return action.data;
    default:
      return state;
  }
};

export const fetchTopic = (id, opts={}) => (dispatch) => {
  return _sp.axios.get(settings.api_base + "/" + settings.api_version + '/topic/' + id, { params: opts })
    .then(res => {
      return res.data;
    })
    .then(data => {
      dispatch({
        type: "TOPIC_LOADED",
        data: data,
      });
    })
};

export const topicReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case "TOPIC_LOADED":
      return action.data;
    default:
      return state;
  }
};