import React, {Component} from 'react';
import { Link } from "react-router-dom";

class SocialBar extends Component {
  render() {
    return (
      <div className={'social-bar ' + this.props.className}>
        <Link to={'/report?type=' + this.props.type + "&slug=" + (this.props.object.slug || this.props.object.id)}><i className={'far fa-flag'} /></Link>
      </div>
    );
  }
}

export default SocialBar;