import React from "react"
import PropTypes from "prop-types"

class VisibleEvent extends React.Component {
  static defaultProps = {
    offset: 250
  };

  constructor(props) {
    super(props);

    this._scrolled = true;
    this.onScroll = this.onScroll.bind(this);
    this.checkVisible = this.checkVisible.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll);
    this._interval = setInterval(this.checkVisible, 250);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
    clearInterval(this._interval);
  }

  onScroll() {
    this._scrolled = true;
  }

  checkVisible() {
    if (!this._scrolled) {
      return;
    }

    this._scrolled = false;

    if (_sp.dom.inViewport(this._element, this.props.offset)) {
      if (typeof(this.props.inViewport) === "function") {
        this.props.inViewport();
      }
    } else {
      if (typeof(this.props.outOfViewport) === "function") {
        this.props.outOfViewport();
      }
    }
  }

  render () {
    return (
      <div ref={(e) => { this._element = e; }}>
        {this.props.children}
      </div>
    );
  }
}

VisibleEvent.propTypes = {
  inViewport: PropTypes.func,
  outOfViewport: PropTypes.func,
  offset: PropTypes.number
};

export default VisibleEvent
