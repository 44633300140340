export const fetchPornstars = (opts={}) => (dispatch) => {
  let index = opts.index || "all";

  return _sp.axios.get(settings.api_base + "/" + settings.api_version + '/pornstar', {
    params: opts
  })
    .then(res => {
      return res.data;
    })
    .then(data => {
      dispatch({
        type: "PORNSTARS_LOADED",
        data: data,
        index: index
      });
    })
};

export const pornstarsReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case 'PORNSTARS_LOADED':
      if (!action.data.objects) {
        action.data.objects = [];
      }

      let existing = state[action.index];

      let objects = (existing ? existing.objects : []).concat(action.data.objects);

      return Object.assign({}, state, {
        [`${action.index}`]: {
          loading: false,
          objects: objects,
          meta_tags: action.data.meta_tags,
          pagination: action.data.pagination,
          util: action.data.util
        }
      });
    default:
      return state;
  }
};

export const fetchPornstar = (id, opts={}) => (dispatch) => {
  return _sp.axios.get(settings.api_base + "/" + settings.api_version + '/pornstar/' + id, {
    params: opts
  })
    .then(res => {
      return res.data;
    })
    .then(data => {
      dispatch({
        type: "PORNSTAR_LOADED",
        data: data
      });
    })
};

export const pornstarReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case "PORNSTAR_LOADED":
      if (!action.data.videos) {
        action.data.videos = [];
      }

      if (!action.data.images) {
        action.data.images = [];
      }

      return action.data;
    default:
      return state;
  }
};